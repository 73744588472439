<template>
  <MessageResultDiv :messageResult="messageResult" :gotError="gotError" />
  <Toast :toastIsShowed="toastIsShowed" :gotError="gotError" :toastMessage="toastMessage" />

  <div ref="divModalWindow" class="modal fade" tabindex="-1" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-body">

          <div class="row">
            <div class="col-12">
              <span class="fw-bold">{{textModalHeader}}</span>
              <button type="button" class="btn-close float-end" aria-label="Close" @click="hideModalWindow"></button>
            </div>
          </div>

          <div class="row mt-2">
            <div class="col-12" >
              <span v-html="textModalContent"></span>
            </div>
          </div>

        </div>
      </div>
    </div>
  </div>


  <div class="row">
    <div class="col-auto d-inline mt-2">
      <select class="form-select"  v-model="selectedWh" @change="selectChange">
        <option value="0">
          Все склады
        </option>

        <option v-for="wh in arrWbWhs" :value="wh.id">
          {{ wh.name }}
        </option>
      </select>
    </div>

    <div class="col-auto d-inline mt-2">
      <p><router-link :to="{ name: 'WbWhRel'}" target="_blank" class="fw-normal">Настроить склады</router-link>  </p>
    </div>

  </div>

  <div class="row">
    <div class="col-auto d-inline mt-2">
      <input v-model="dateStart" type="date" id="inputDateStart" class="form-control" @change="selectChange">
    </div>

    <div class="col-auto d-inline mt-2">
      <label for="inputDateEnd" class="col-form-label">по</label>
    </div>

    <div class="col-auto d-inline mt-2">
      <input v-model="dateEnd" type="date" id="inputDateEnd" class="form-control" @change="selectChange">
    </div>
  </div>

  <h5>ВБ Заказы</h5>

  <div class="row text-center">
      <div style="width: 440px; height: 145px" class="mt-2">
        <BarChartUniversal :chartData = "totalWbChartData"  withYAxis @click-barchart="onClickWbBarchartBarcodes"/>
      </div>
  </div>


  <div class="row">
    <div class="col-auto mt-2">
      <UniversalTable
        :items="filteredRowsWbBarcodes"
        :allHeaders="allHeadersWbBarcodes"
        localStorageHeadersName="DashBoardBarcodes_headers"
        :arrTable="arrTableWbBarcodes"
        tableClass="table-striped  table-hover table-sm"
      />
    </div>
  </div>

  <div class="row">
    <div class="col-auto mt-2">
      <p>Скройте ненужные товары в разделе <router-link :to="{ name: 'WbOwnProductList'}" target="_blank" class="fw-normal">"ВБ товары"</router-link>  </p>
    </div>
  </div>

  <h5>ВБ Реклама</h5>

  <div class="row">
    <div class="col-auto mt-2">
      <UniversalTable
        :items="filteredRowsWbAdv"
        :allHeaders="allHeadersWbAdv"
        localStorageHeadersName="DashBoardAdv_headers"
        :arrTable="arrTableWbAdv"
        tableClass="table-striped table-hover table-sm"
      />
    </div>
  </div>


  <h5>ОЗОН Заказы</h5>

  <div class="row text-center">
      <div style="width: 440px; height: 145px" class="mt-2">
        <BarChartUniversal :chartData = "totalOzonChartData"  withYAxis @click-barchart="onClickOzonBarchartProducts"/>
      </div>
  </div>


  <div class="row">
    <div class="col-auto mt-2">
      <UniversalTable
        :items="filteredRowsOzonProducts"
        :allHeaders="allHeadersOzonProducts"
        localStorageHeadersName="DashBoardOzonProducts_headers"
        :arrTable="arrTableOzonProducts"
        tableClass="table-striped  table-hover table-sm"
      />
    </div>
  </div>

  <div class="row">
    <div class="col-auto mt-2">
      <p>Скройте ненужные товары в разделе <router-link :to="{ name: 'OzonOwnProductList'}" target="_blank" class="fw-normal">"ОЗОН товары"</router-link>  </p>
    </div>
  </div>

</template>

<script>
import BarChartUniversal from '../components/BarChartUniversal.vue'
import UniversalTable from "@/components/UniversalTable";

import {Modal, Popover} from 'bootstrap'

import {
  fetchDrf,
  TYPE_COL_ADV_OWN_PRODUCTS,
  TYPE_COL_ADV_STATUS,
  TYPE_COL_WB_ORDERS_BARCHART,
  TYPE_COL_OZON_ORDERS_BARCHART,
  TYPE_COL_ADV_TYPE_NAME_SHORT,
  TYPE_COL_ADV_STATS_BARCHART,
  prepareNumber,
  prepareDate,
} from "@/common";
// import {ref} from "vue";

export default {
  name: "DashBoard",

  components: {
    BarChartUniversal,
    UniversalTable,
  },

  data() {
    return {
      dateStart: "",
      dateEnd: "",

      toastIsShowed: false,
      toastDelay: 5000,
      toastMessage: "",

      filterStringProducts: "",

      arrTableWbBarcodes: [],
      columnsBarcodes: [],
      allHeadersWbBarcodes: [],

      arrTableWbAdv: [],
      columnsAdv: [],
      allHeadersWbAdv: [],

      arrTableOzonProducts: [],
      columnsProducts: [],
      allHeadersOzonProducts: [],

      COL_NAME: 'name',

      COL_STOCK_QUANTITY: 'stock_quantity',
      COL_STOCK_QUANTITY_WAITINGDOCS: 'stock_quantity_waitingdocs',
      COL_STOCK_QUANTITY_EXPIRING: 'stock_quantity_expiring',
      COL_STOCK_QUANTITY_DEFECT: 'stock_quantity_defect',

      COL_WB_ORDERS_BARCHART: 'wb_orders_barchart',
      COL_OZON_ORDERS_BARCHART: 'ozon_orders_barchart',
      COL_ORDERS_QUANTITY: 'orders_quantity_total',
      COL_ORDERS_SUM: 'orders_sum_total',
      COL_DRR_PERCENT: 'drr_percent',
      COL_ADV_COST: 'adv_cost',

      COL_FOTO: 'foto_link',
      COL_ARTICLE_WB: 'nm_id',
      COL_ARTICLE: 'article',
      COL_SKU: 'sku',

      COL_WB_ID: 'wb_id',
      COL_NM_IDS: 'nm_ids',
      COL_ADV_TYPE: 'adv_type',
      COL_ADV_TYPE_NAME: 'adv_type_name',
      COL_STATUS: 'status',
      COL_STATUS_NAME: 'status_name',
      COL_BET_CURRENT: 'bet_current',
      COL_ADV_TYPE_NAME_SHORT: 'adv_type_name_short',

      COL_ADV_STATS_BARCHART: 'adv_stats_barchart',

      COL_WB_STOCK_DETAILS: "wb_stock_details",
      COL_WB_ORDER_DETAILS: "wb_order_details",

      TYPE_COL_WB_ORDERS_BARCHART: "",
      TYPE_COL_OZON_ORDERS_BARCHART: "",

      TYPE_COL_ADV_STATS_BARCHART: "",

      COL_OZON_STOCK_DETAILS: "ozon_stock_details",
      COL_OZON_ORDER_DETAILS: "ozon_order_details",

      selectedWh: 0,

      arrWbTotal: [],
      arrWbBarcodes: [],
      arrWbAdv: [],
      arrWbWhs: [],

      arrOzonTotal: [],
      arrOzonProducts: [],

      divModalWindow: undefined,
      textModalHeader: "",
      textModalContent: "",

      messageResult: "",
      gotError: false,
    }
  },

  async created() {
    document.title = "Дашборд"

    this.TYPE_COL_WB_ORDERS_BARCHART = TYPE_COL_WB_ORDERS_BARCHART
    this.TYPE_COL_OZON_ORDERS_BARCHART = TYPE_COL_OZON_ORDERS_BARCHART
    this.TYPE_COL_ADV_STATUS = TYPE_COL_ADV_STATUS;
    this.TYPE_COL_ADV_OWN_PRODUCTS = TYPE_COL_ADV_OWN_PRODUCTS;
    this.TYPE_COL_ADV_TYPE_NAME_SHORT = TYPE_COL_ADV_TYPE_NAME_SHORT;
    this.TYPE_COL_ADV_STATS_BARCHART = TYPE_COL_ADV_STATS_BARCHART;

    this.allHeadersWbBarcodes.push({id: this.COL_FOTO, name: "Фото", active: true, title: "name", type: "foto" })
    this.allHeadersWbBarcodes.push({id: this.COL_ARTICLE_WB, name: "Артикул ВБ", active: false })
    this.allHeadersWbBarcodes.push({id: this.COL_ARTICLE, name: "Артикул", active: false })
    this.allHeadersWbBarcodes.push({id: this.COL_STOCK_QUANTITY, name: "Остаток", active: true, type: "int", totalRow: true, modal_window_content: this.COL_WB_STOCK_DETAILS })
    this.allHeadersWbBarcodes.push({id: this.COL_ORDERS_QUANTITY, name: "Заказы", active: true, type: "int", totalRow: true, modal_window_content: this.COL_WB_ORDER_DETAILS })
    this.allHeadersWbBarcodes.push({id: this.COL_ORDERS_SUM, name: "Сумма", active: false, type: "int", totalRow: true })
    this.allHeadersWbBarcodes.push({id: this.COL_DRR_PERCENT, name: "ДРР %", active: true, type: "int" })
    this.allHeadersWbBarcodes.push({id: this.COL_WB_ORDERS_BARCHART, name: "График заказов", active: true, type: this.TYPE_COL_WB_ORDERS_BARCHART })

    this.allHeadersWbAdv.push({id: this.COL_ADV_OWN_PRODUCTS, name: "Номенклатура", active: true, type: this.TYPE_COL_ADV_OWN_PRODUCTS, title: this.COL_NAME})
    this.allHeadersWbAdv.push({id: this.COL_ADV_TYPE_NAME, name: "Тип", active: true, type: this.TYPE_COL_ADV_TYPE_NAME_SHORT, title: this.COL_ADV_TYPE_NAME })
    this.allHeadersWbAdv.push({id: this.COL_STATUS_NAME, name: "Статус", active: true, type: this.TYPE_COL_ADV_STATUS })
    this.allHeadersWbAdv.push({id: this.COL_BET_CURRENT, name: "₽", active: true, type: "int" })
    this.allHeadersWbAdv.push({id: this.COL_ADV_STATS_BARCHART, name: "График показов", active: true, type: this.TYPE_COL_ADV_STATS_BARCHART })
    this.allHeadersWbAdv.push({id: this.COL_NAME, name: "Название", active: false })
    this.allHeadersWbAdv.push({id: this.COL_WB_ID, name: "ID", active: false })

    this.allHeadersOzonProducts.push({id: this.COL_FOTO, name: "Фото", active: true, title: "name", type: "foto" })
    this.allHeadersOzonProducts.push({id: this.COL_ARTICLE, name: "Артикул", active: true })
    this.allHeadersOzonProducts.push({id: this.COL_SKU, name: "SKU", active: true })
    this.allHeadersOzonProducts.push({id: this.COL_STOCK_QUANTITY, name: "Остаток", active: true, type: "int", totalRow: true , modal_window_content: this.COL_OZON_STOCK_DETAILS})
    this.allHeadersOzonProducts.push({id: this.COL_ORDERS_QUANTITY, name: "Заказы", active: true, type: "int", totalRow: true , modal_window_content: this.COL_OZON_ORDER_DETAILS})
    this.allHeadersOzonProducts.push({id: this.COL_OZON_ORDERS_BARCHART, name: "График показов", active: true, type: this.TYPE_COL_OZON_ORDERS_BARCHART })

    this.initDates()
    this.loadDashBoardData()
  },

  computed:{
    totalWbChartData(){
      let dates = []
      let quantitys = []
      let sums = []
      let labels = []

      let allWhs = {}

      for(let i = 0; i < this.arrWbTotal.length; i++ ){
        let orders_wh = this.arrWbTotal[i]["orders_wh"]

        for (let key in orders_wh){
          let whName =  orders_wh[key]["wh_name"]
          let whColor = orders_wh[key]["wh_color"]

          if (!whColor) whColor = 'grey'

          allWhs[key] = {
            whName: whName,
            whColor: whColor
          }
        }
      }

      // Создадим и заполним нулями
      let sums_wh = {}
      let quantitys_wh = {}

      for (let key in allWhs){
        sums_wh[key] = []
        quantitys_wh[key] = []

        for(let i = 0; i < this.arrWbTotal.length; i++ ){
          sums_wh[key][i] = 0;
          quantitys_wh[key][i] = 0;
        }
      }

      for(let i = 0; i < this.arrWbTotal.length; i++ ){
        dates.push(this.arrWbTotal[i]["date"])
        labels.push( `${this.arrWbTotal[i]["date"]}` )
        quantitys.push(this.arrWbTotal[i]["quantity"])
        sums.push(this.arrWbTotal[i]["sum"])

        let orders_wh = this.arrWbTotal[i]["orders_wh"]

        for (let key in orders_wh){
          sums_wh[key][i] = orders_wh[key]["sum"]
          quantitys_wh[key][i] = orders_wh[key]["quantity"]
        }
      }

      let chartData = {
        labels: dates,
        datasets: [],
      }

      for (let key in allWhs){
        let whName = allWhs[key]["whName"]
        let whColor = allWhs[key]["whColor"]

        chartData["datasets"].push({
          label: whName,
          backgroundColor: whColor,
          data: sums_wh[key],
          barThickness: 20,
        })
      }

      return chartData
    },


    totalOzonChartData(){
      let dates = []
      let quantitys = []
      let sums = []
      let labels = []

      let allWhs = {}

      let defaultName = "Заказы"
      let defaultColor = "grey"

      allWhs[defaultName] = {
        whName: defaultName,
        whColor: defaultColor
      }

      // for(let i = 0; i < this.arrWbTotal.length; i++ ){
      //   let orders_wh = this.arrWbTotal[i]["orders_wh"]

        // for (let key in orders_wh){
        //   let whName =  orders_wh[key]["wh_name"]
        //   let whColor = orders_wh[key]["wh_color"]
        //
        //   if (!whColor) whColor = 'grey'
        //
        //   allWhs[key] = {
        //     whName: whName,
        //     whColor: whColor
        //   }
        // }
      // }

      // Создадим и заполним нулями
      let sums_wh = {}
      let quantitys_wh = {}

      for (let key in allWhs){
        sums_wh[key] = []
        quantitys_wh[key] = []

        for(let i = 0; i < this.arrOzonTotal.length; i++ ){
          sums_wh[key][i] = 0;
          quantitys_wh[key][i] = 0;
        }
      }

      for(let i = 0; i < this.arrOzonTotal.length; i++ ){
        dates.push(this.arrOzonTotal[i]["date"])
        labels.push( `${this.arrOzonTotal[i]["date"]}` )
        quantitys.push(this.arrOzonTotal[i]["quantity"])
        sums.push(this.arrOzonTotal[i]["sum"])

        // let orders_wh = this.arrOzonTotal[i]["orders_wh"]

        // for (let key in orders_wh){
        // }
        sums_wh[defaultName][i] = this.arrOzonTotal[i]["sum"]
        quantitys_wh[defaultName][i] = this.arrOzonTotal[i]["quantity"]
      }

      let chartData = {
        labels: dates,
        datasets: [],
      }

      for (let key in allWhs){
        let whName = allWhs[key]["whName"]
        let whColor = allWhs[key]["whColor"]

        chartData["datasets"].push({
          label: whName,
          backgroundColor: whColor,
          data: sums_wh[key],
          barThickness: 20,
        })
      }

      return chartData
    },


    filteredRowsWbBarcodes() {
      return this.arrTableWbBarcodes.filter(row => {

        return true // !!!!!!!

        const filterString = this.filterString.toString().toLowerCase().trim();

        const whName = (row[this.COL_WH_NAME] || '').toString().toLowerCase();
        let bTextFilterOk = false

        if ( whName.includes(filterString) ){
          bTextFilterOk = true
        }

        return bTextFilterOk
      });
    },


    filteredRowsOzonProducts() {
      return this.arrTableOzonProducts.filter(row => {

        return true // !!!!!!!

        const filterString = this.filterString.toString().toLowerCase().trim();

        const whName = (row[this.COL_WH_NAME] || '').toString().toLowerCase();
        let bTextFilterOk = false

        if ( whName.includes(filterString) ){
          bTextFilterOk = true
        }

        return bTextFilterOk
      });
    },


    filteredRowsWbAdv() {
      return this.arrTableWbAdv.filter(row => {

        return true // !!!!!!!

        const filterString = this.filterString.toString().toLowerCase().trim();

        const whName = (row[this.COL_WH_NAME] || '').toString().toLowerCase();
        let bTextFilterOk = false

        if ( whName.includes(filterString) ){
          bTextFilterOk = true
        }

        return bTextFilterOk
      });
    },
  },

  methods: {
    showModalWindow(){
      this.divModalWindow = new Modal(this.$refs.divModalWindow)
      this.divModalWindow.show()
    },

    hideModalWindow(){
      this.divModalWindow.hide()
    },

    showModalWindowClick(item, header){
      this.textModalHeader = header["name"]
      this.textModalContent = item[header.modal_window_content]

      this.showModalWindow()
    },

    onClickWbBarchartBarcodes(index, item){
      if (index === undefined) return;

      let orders_wh = this.arrWbTotal[index]["orders_wh"]

      if (!orders_wh) return

      let sOrdersWh = "" ;
      let totalSum = 0;
      let totalSumSpp = 0;
      let totalQuantity = 0;
      let date = this.arrWbTotal[index]["date"]

      for (let key in orders_wh) {
        let quantity = orders_wh[key]["quantity"]
        let sum = orders_wh[key]["sum"]
        let sumSpp = orders_wh[key]["sum_spp"]
        let wh_name = orders_wh[key]["wh_name"]

        let wh_color = orders_wh[key]["wh_color"]
        if (!wh_color) wh_color = 'grey'

        totalSum += sum
        totalSumSpp += sumSpp
        totalQuantity += quantity

        sOrdersWh += `<span class="badge" style="background-color: ${wh_color}">&nbsp;</span> ${wh_name}: <b>${sum}</b> (${quantity}) <br/>`
      }

      let avgSum = 0
      let avgSumSpp = 0
      let avgSppDiscountPercent = 0

      if (totalQuantity) {
        avgSum = Math.floor(totalSum / totalQuantity)
        avgSumSpp = Math.floor(totalSumSpp / totalQuantity)
      }
      if (totalSum) avgSppDiscountPercent = Math.floor((1 - (totalSumSpp/totalSum)) * 100)

      if (!avgSumSpp) avgSppDiscountPercent = undefined

      sOrdersWh += `<hr>`
      sOrdersWh += `Всего заказов: <b>${prepareNumber(totalQuantity)}</b> <br/>`
      sOrdersWh += `Сумма: <b>${prepareNumber(totalSum)}</b> <br/>`

      sOrdersWh += `Средняя цена: <b>${prepareNumber(avgSum)}</b> <br/>`
      sOrdersWh += `Средняя цена с СПП: <b>${prepareNumber(avgSumSpp)}</b> <br/>`
      sOrdersWh += `Средняя СПП: <b>${prepareNumber(avgSppDiscountPercent)}%</b> <br/>`

      this.textModalHeader = `Заказы за ${prepareDate(date)}`
      this.textModalContent = sOrdersWh
      this.showModalWindow()
    },

    onClickOzonBarchartProducts(index, item){
      if (index === undefined) return;

      let date = this.arrOzonTotal[index]["date"]
      let totalSum = this.arrOzonTotal[index]["sum"]
      let totalQuantity = this.arrOzonTotal[index]["quantity"]

      let sOrdersWh = "" ;
      let avgSum = 0

      if (totalQuantity) {
        avgSum = Math.floor(totalSum / totalQuantity)
      }

      sOrdersWh += `<hr>`
      sOrdersWh += `Всего заказов: <b>${prepareNumber(totalQuantity)}</b> <br/>`
      sOrdersWh += `Сумма: <b>${prepareNumber(totalSum)}</b> <br/>`
      sOrdersWh += `Средняя цена: <b>${prepareNumber(avgSum)}</b> <br/>`

      this.textModalHeader = `Заказы за ${prepareDate(date)}`
      this.textModalContent = sOrdersWh
      this.showModalWindow()
    },

    async prepareTable(){
      for (let i = 0; i < this.arrTableWbBarcodes.length; i++){
        // stocks wb
        this.arrTableWbBarcodes[i][this.COL_WB_STOCK_DETAILS] = "";

        let sWbStockDetails = ""
        let wb_stocks_wh = this.arrTableWbBarcodes[i]["stocks_wh"]

        if (!wb_stocks_wh) continue

        for (let j = 0; j < wb_stocks_wh.length; j++){
          let color = wb_stocks_wh[j]["color"]
          if (!color) color = "grey"
          sWbStockDetails += `<span class="badge" style="background-color: ${color}">&nbsp;</span> ${wb_stocks_wh[j]["name"]} : <b>${wb_stocks_wh[j]["quantity"]}</b> <br/>`
        }

        this.arrTableWbBarcodes[i][this.COL_WB_STOCK_DETAILS] = sWbStockDetails;

        // orders wb
        let sWbOrderDetails = ""

        let wb_orders_wh = this.arrTableWbBarcodes[i]["orders_wh"]

        if (!wb_orders_wh) wb_orders_wh = []

        for (let j = 0; j < wb_orders_wh.length; j++){
          let color = wb_orders_wh[j]["color"]
          if (!color) color = "grey"
          sWbOrderDetails += `<span class="badge" style="background-color: ${color}">&nbsp;</span> ${wb_orders_wh[j]["name"]} : <b>${wb_orders_wh[j]["quantity"]}</b> <br/>`
        }

        sWbOrderDetails += `<hr/>`
        sWbOrderDetails += `Всего заказов: <b>${prepareNumber(this.arrTableWbBarcodes[i][this.COL_ORDERS_QUANTITY])}</b>  <br/>`
        sWbOrderDetails += `Сумма: <b>${prepareNumber(this.arrTableWbBarcodes[i][this.COL_ORDERS_SUM])}</b>  <br/>`
        sWbOrderDetails += `ДРР: <b>${prepareNumber(this.arrTableWbBarcodes[i][this.COL_DRR_PERCENT])}</b> % <br/>`
        sWbOrderDetails += `Затраты на рекламу: <b>${prepareNumber(this.arrTableWbBarcodes[i][this.COL_ADV_COST])}</b> <br/>`

        this.arrTableWbBarcodes[i][this.COL_WB_ORDER_DETAILS] = sWbOrderDetails;
      }

      for (let i = 0; i < this.arrTableOzonProducts.length; i++) {
        // stocks ozon
        this.arrTableOzonProducts[i][this.COL_OZON_STOCK_DETAILS] = "";

        let sOzonStockDetails = ""
        let ozon_stocks_wh = this.arrTableOzonProducts[i]["stocks_wh"]

        if (!ozon_stocks_wh) continue

        for (let j = 0; j < ozon_stocks_wh.length; j++) {
          let color = ozon_stocks_wh[j]["color"]
          if (!color) color = "grey"
          sOzonStockDetails += `<span class="badge" style="background-color: ${color}">&nbsp;</span> ${ozon_stocks_wh[j]["name"]} : <b>${ozon_stocks_wh[j]["quantity"]}</b> <br/>`
        }

        sOzonStockDetails += `<hr/>`
        sOzonStockDetails += `Остаток, доступный для продажи: <b>${prepareNumber(this.arrTableOzonProducts[i][this.COL_STOCK_QUANTITY])}</b>  <br/>`
        sOzonStockDetails += `<span style="color: grey">Остаток, который ждет документы: <b>${prepareNumber(this.arrTableOzonProducts[i][this.COL_STOCK_QUANTITY_WAITINGDOCS], '-')}</b></span> <br/>`
        sOzonStockDetails += `<span style="color: grey">Остаток, где истекает срок годности: <b>${prepareNumber(this.arrTableOzonProducts[i][this.COL_STOCK_QUANTITY_EXPIRING], '-')}</b></span>  <br/>`
        sOzonStockDetails += `<span style="color: grey">Остаток с дефектами: <b>${prepareNumber(this.arrTableOzonProducts[i][this.COL_STOCK_QUANTITY_DEFECT], '-')}</b></span>  <br/>`

        this.arrTableOzonProducts[i][this.COL_OZON_STOCK_DETAILS] = sOzonStockDetails;

        // orders ozon
        let sOzonOrderDetails = ""

        let ozon_orders_wh = this.arrTableOzonProducts[i]["orders_wh"]

        if (!ozon_orders_wh) ozon_orders_wh = []

        for (let j = 0; j < ozon_orders_wh.length; j++){
          let color = ozon_orders_wh[j]["color"]
          if (!color) color = "grey"
          sOzonOrderDetails += `<span class="badge" style="background-color: ${color}">&nbsp;</span> ${ozon_orders_wh[j]["name"]} : <b>${ozon_orders_wh[j]["quantity"]}</b> <br/>`
        }

        let avgPrice = 0
        if (this.arrTableOzonProducts[i][this.COL_ORDERS_QUANTITY]){
          avgPrice = this.arrTableOzonProducts[i][this.COL_ORDERS_SUM] / this.arrTableOzonProducts[i][this.COL_ORDERS_QUANTITY]
        }

        sOzonOrderDetails += `<hr/>`
        sOzonOrderDetails += `Всего заказов: <b>${prepareNumber(this.arrTableOzonProducts[i][this.COL_ORDERS_QUANTITY])}</b>  <br/>`
        sOzonOrderDetails += `Сумма: <b>${prepareNumber(this.arrTableOzonProducts[i][this.COL_ORDERS_SUM])}</b>  <br/>`
        sOzonOrderDetails += `Средняя цена: <b>${prepareNumber(avgPrice)}</b>  <br/>`

        this.arrTableOzonProducts[i][this.COL_OZON_ORDER_DETAILS] = sOzonOrderDetails;
      }
    },

    async selectChange(){
      await this.loadDashBoardData()
    },

    initDates() {
      this.dateEnd = new Date().toISOString().slice(0, 10);

      let dateStart = new Date();
      dateStart.setDate(dateStart.getDate() - 14)
      this.dateStart = dateStart.toISOString().slice(0, 10);
    },

    async loadDashBoardData() {

      let searchParams = new URLSearchParams({
        dateStart: this.dateStart,
        dateEnd: this.dateEnd,
        wh: this.selectedWh,
      })

      let res = await fetchDrf(`/dashboard/?${searchParams}`)

      console.log("res")
      console.log(res)

      if (!res.gotError) {
        this.arrWbTotal = res.data[0].wb_total;
        this.arrTableWbBarcodes = res.data[0].wb_barcodes;
        this.arrTableWbAdv = res.data[0].wb_adv;
        this.arrWbWhs = res.data[0].wb_whs;

        this.arrOzonTotal = res.data[0].ozon_total;
        this.arrTableOzonProducts = res.data[0].ozon_products;

        this.prepareTable()
      }
      else {
        this.messageResult = res.messageResult;
        this.gotError = res.gotError;
      }
    },
  },
}
</script>

<style scoped>
  .popover {
    display: none;
    position: absolute;
    background-color: #fff;
    border: 1px solid #ccc;
    border-radius: 5px;
    box-shadow: 0 2px 5px rgba(0,0,0,0.2);
    padding: 10px;
  }
  .popover-header {
    font-weight: bold;
  }
  .popover-body {
    color: #333;
  }
</style>