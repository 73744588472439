<template xmlns="http://www.w3.org/1999/html">
  <div class="row">
    <h1>{{getHeaderText}}</h1>
  </div>

  <div class="row">
    <div class="col-sm-auto mt-2">
      <span class="text-secondary">Токены можно взять в личном кабинете ОЗОН в <a href="https://seller.ozon.ru/app/settings/api-keys" target="_blank"> Настройки → Seller API</a>
      (поставьте только галочку "Admin read only", обычно внизу списка).</span>
    </div>
  </div>


  <div class="row">
    <div v-if="messageResult" class="alert " :class="{ 'alert-danger': gotError, 'alert-success': !gotError }"  role="alert">
      {{ messageResult }}
    </div>
  </div>


  <div class="row">
    <div class="col-sm-auto mt-2">
      <div class="form-group">
        <label for="inputName">Название (юр лицо)</label>
        <input v-model="name" type="text" class="form-control" id="inputName" placeholder="">
      </div>
    </div>
  </div>

  <div class="row">
    <div class="col-sm-auto mt-2">
      <div class="form-group">
        <label for="inputClientIdOzon">Client ID</label>
        <input v-model="client_id_ozon" type="text" class="form-control" id="inputClientIdOzon" placeholder="">
      </div>
    </div>
  </div>

  <div class="row">
    <div class="col-sm-auto mt-2">
      <div class="form-group">
        <label for="inputTokenOzon">Токен</label>
        <textarea v-model="token_ozon" type="text" class="form-control" id="inputTokenOzon" placeholder="" rows="6"></textarea>
      </div>
    </div>
  </div>


  <div class="row">
    <div class="col-auto">
      <button @click="updateOzonLk" type="button" class="btn btn-primary mt-3">Сохранить</button>
    </div>
    <div class="col-auto">
      <router-link :to="{name: 'OzonLkList'}" class="btn btn-warning mt-3">Вернуться</router-link>
    </div>
  </div>

</template>

<script>
import {fetchDrf} from "@/common";

export default {
  name: "OzonLkEdit",
  props: ['id'],
  data(){
    return {
      ozon_lk: undefined,
      name: "",
      client_id_ozon: "",
      token_ozon: "",
      messageResult: "",
      gotError: false
    }
  },

  created() {
    document.title = "Аккаунт ОЗОН"
    this.loadOzonLk();
  },

  computed: {
    getHeaderText() {
       if (this.id == 0) return "Добавить аккаунт ОЗОН";
       else return "Редактировать аккаунт ОЗОН";
    },
  },

  methods: {

    async loadOzonLk() {
      // Если создаем с нуля - нечего подгружать
      if (this.id == 0) return

      let res = await fetchDrf(`/ozon-lk/${this.id}/`)

      if (!res.gotError){
          this.name = res.data["name"]
          this.client_id_ozon = res.data["client_id_ozon"]
          this.token_ozon = res.data["token_ozon"]
      }
      else {
       this.messageResult = res.messageResult;
       this.gotError = res.gotError
      }
    },

    async updateOzonLk() {
      if (this.id != 0) {
        let jsonBody = {
          name: this.name,
          client_id_ozon: this.client_id_ozon,
          token_ozon: this.token_ozon,
        }

        let res = await fetchDrf(`/ozon-lk/${this.id}/`,
            "PUT",
            jsonBody
        )

        this.messageResult = res.messageResult;
        this.gotError = res.gotError

        await this.loadOzonLk()
      }

      // Создаем новую запись
      else {
        let jsonBody = {
          name: this.name,
          client_id_ozon: this.client_id_ozon,
          token_ozon: this.token_ozon,
        }

        let res = await fetchDrf(`/ozon-lk/`,
                                 "POST",
                                 jsonBody)

        if (!res.gotError){
          this.$router.push({name: 'OzonLkList'})
        }
        else {
          this.messageResult = res.messageResult;
          this.gotError = res.gotError
        }
      }
    }
  }
}
</script>

<style scoped>

</style>