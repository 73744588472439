import { createRouter, createWebHistory } from 'vue-router'

import OzonLkList from '@/views/OzonLkList';
import OzonLkEdit from "@/views/OzonLkEdit";

import OzonOwnProductList from '@/views/OzonOwnProductList';

import WbLkList from '@/views/WbLkList';
import WblkEdit from "@/views/WbLkEdit";

import WbSearchProductList from '@/views/WbSearchProductList';
import WbSearchProductEdit from '@/views/WbSearchProductEdit';
import WbSearchProductAnalytics from '@/views/WbSearchProductAnalytics';

import WbOwnProductList from '@/views/WbOwnProductList';
import WbOwnProductEdit from '@/views/WbOwnProductEdit';
import WbOwnProductAnalytics from '@/views/WbOwnProductAnalytics';

import WbOwnRReportList from '@/views/WbOwnRReportList';
import WbOwnRReportEdit from '@/views/WbOwnRReportEdit';
import WbOwnRReportDistrExpenses from '@/views/WbOwnRReportDistrExpenses';

import WbOwnAdvList from "@/views/WbOwnAdvList";
import WbOwnAdvEdit from "@/views/WbOwnAdvEdit";
import WbAdvKeywords from "@/views/WbAdvKeywords";

import WbAdvBetsRequestList from "@/views/WbAdvBetsRequestList";
import WbAdvBetsRequestEdit from "@/views/WbAdvBetsRequestEdit";

import WbOwnCostPriceList from "@/views/WbOwnCostPriceList";

import UserSelfEdit from "@/views/UserSelfEdit";
import UserRegister from "@/views/UserRegister";
import UserEnter from "@/views/UserEnter";
import UserRestorePassword from "@/views/UserRestorePassword";
import WbAdmin from "@/views/WbAdmin";

import PageHome from "@/views/PageHome";
import PageStart from "@/views/PageStart";
import DashBoard from "@/views/DashBoard";

import WbWhRel from "@/views/WbWhRel";


const routes = [

  {
    path: '/ozon-lk/',
    name: 'OzonLkList',
    component: OzonLkList,
    meta:{
       layout: "CabinetTopMenuLayout"
    }
  },
  {
    path: '/ozon-lk/edit/:id',
    name: 'OzonLkEdit',
    component: OzonLkEdit,
    props: true,
    meta:{
       layout: "CabinetTopMenuLayout"
    }
  },

  {
    path: '/ozon-own-product/',
    name: 'OzonOwnProductList',
    component: OzonOwnProductList,
    meta:{
       layout: "CabinetTopMenuLayout"
    }
  },

  {
    path: '/wb-own-cost-price/',
    name: 'WbOwnCostPriceList',
    component: WbOwnCostPriceList,
    meta:{
       layout: "CabinetTopMenuLayout"
    }
  },

  {
    path: '/wb-own-product/',
    name: 'WbOwnProductList',
    component: WbOwnProductList,
    meta:{
       layout: "CabinetTopMenuLayout"
    }
  },

  {
    path: '/wb-own-product/edit/:id',
    name: 'WbOwnProductEdit',
    component: WbOwnProductEdit,
    props: true,
    meta:{
       layout: "CabinetTopMenuLayout"
    }
  },

  {
    path: '/wb-own-product/a/:id',
    name: 'WbOwnProductAnalytics',
    component: WbOwnProductAnalytics,
    props: true,
    meta:{
       layout: "CabinetTopMenuLayout"
    }
  },


  {
    path: '/wb-own-rreport/',
    name: 'WbOwnRReportList',
    component: WbOwnRReportList,
    meta:{
       layout: "CabinetTopMenuLayout"
    }
  },


  {
    path: '/wb-own-rreport/distr-expenses/:id',
    name: 'WbOwnRReportDistrExpenses',
    component: WbOwnRReportDistrExpenses,
    props: true,
    meta:{
       layout: "CabinetTopMenuLayout"
    }
  },


  {
    path: '/wb-own-rreport/edit/:id',
    name: 'WbOwnRReportEdit',
    component: WbOwnRReportEdit,
    props: true,
    meta:{
       layout: "CabinetTopMenuLayout"
    }
  },


  {
    path: '/wb-search-product/',
    name: 'WbSearchProductList',
    component: WbSearchProductList,
    meta:{
       layout: "CabinetTopMenuLayout"
    }
  },

  {
    path: '/wb-search-product/edit/:id',
    name: 'WbSearchProductEdit',
    component: WbSearchProductEdit,
    props: true,
    meta:{
       layout: "CabinetTopMenuLayout"
    }
  },

  {
    path: '/wb-search-product/a/:id',
    name: 'WbSearchProductAnalytics',
    component: WbSearchProductAnalytics,
    props: true,
    meta:{
       // layout: "EmptyLayout"
       layout: "CabinetTopMenuLayout"
    }
  },

  {
    path: '/wb-own-adv/',
    name: 'WbOwnAdvList',
    component: WbOwnAdvList,
    meta:{
       layout: "CabinetTopMenuLayout"
    }
  },
  {
    path: '/wb-own-adv/edit/:id',
    name: 'WbOwnAdvEdit',
    component: WbOwnAdvEdit,
    props: true,
    meta:{
       layout: "CabinetTopMenuLayout"
    }
  },
  {
    path: '/wbadv/keywords/:id',
    name: 'WbAdvKeywords',
    component: WbAdvKeywords,
    props: true,
    meta:{
       layout: "CabinetTopMenuLayout"
    }
  },

  {
    path: '/wbadv-bets',
    name: 'WbAdvBetsRequestList',
    component: WbAdvBetsRequestList,
    meta:{
       layout: "CabinetTopMenuLayout"
    }
  },

  {
    path: '/wbadv-bets/edit/:id',
    name: 'WbAdvBetsRequestEdit',
    component: WbAdvBetsRequestEdit,
    props: true,
    meta:{
       layout: "CabinetTopMenuLayout"
    }
  },


  {
    path: '/wb-lk/',
    name: 'WbLkList',
    component: WbLkList,
    meta:{
       layout: "CabinetTopMenuLayout"
    }
  },
  {
    path: '/wb-lk/edit/:id',
    name: 'WbLkEdit',
    component: WblkEdit,
    props: true,
    meta:{
       layout: "CabinetTopMenuLayout"
    }
  },

  {
    path: '/user-register',
    name: 'UserRegister',
    component: UserRegister,
    meta:{
       layout: "EmptyLayout"
    }
  },
  {
    path: '/user-enter',
    name: 'UserEnter',
    component: UserEnter,
    meta:{
       layout: "EmptyLayout"
    }
  },
  {
    path: '/user-restore-password',
    name: 'UserRestorePassword',
    component: UserRestorePassword,
    meta:{
       layout: "EmptyLayout"
    }
  },
  {
    path: '/user-self-edit',
    name: 'UserSelfEdit',
    component: UserSelfEdit,
    meta:{
       layout: "CabinetTopMenuLayout"
    }
  },
  {
    path: '/wb-admin',
    name: 'WbAdmin',
    component: WbAdmin,
    meta:{
       layout: "CabinetTopMenuLayout"
    }
  },

  {
    path: '/',
    name: 'PageHome',
    component: PageHome,
    meta:{
       layout: "EmptyLayout"
    }
  },

  {
    path: '/start',
    name: 'PageStart',
    component: PageStart,
    meta:{
       layout: "CabinetTopMenuLayout"
    }
  },

  {
    path: '/dashboard',
    name: 'DashBoard',
    component: DashBoard,
    meta:{
       layout: "CabinetTopMenuLayout"
    }
  },

  {
    path: '/wb-wh-rel',
    name: 'WbWhRel',
    component: WbWhRel,
    meta:{
       layout: "CabinetTopMenuLayout"
    }
  },

]

const router = createRouter({
  history: createWebHistory(),
  routes
})

export default router
