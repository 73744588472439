<template>
  <h5>Аккаунты ВБ</h5>

  <div class="row">
    <div class="col-auto">
      <router-link :to="{name: 'UserSelfEdit'}" class="btn btn-info my-1">← вернуться</router-link>
    </div>
  </div>

  <div v-if="messageResult" class="alert " :class="{ 'alert-danger': getError, 'alert-success': !getError }"  role="alert">
    {{ messageResult }}
  </div>

<!--  <router-link :to="{ name: 'WbLkAdd'}" class="btn btn-info mt-3">Добавить аккаунт ВБ</router-link>-->

  <div class="row">
    <div class="col-auto">
      <router-link :to="{ name: 'WbLkEdit', params: { id: 0 }}" class="btn btn-info mt-3">+ Добавить аккаунт ВБ</router-link>
    </div>
  </div>
<!--  <div class="row mt-2">-->
<!--    <div class="col-auto">-->
<!--      <span class="text-secondary">Токены можно взять <a href="https://seller.wildberries.ru/supplier-settings/access-to-api" target="_blank">в личном кабинете ВБ </a> </span>-->
<!--    </div>-->
<!--  </div>-->


  <div v-for="wblk in wblks" :key="wblk.id" class="card  mt-3">
    <div class="card-header">
        {{ wblk.name }}
    </div>
    <div class="card-body" >
      <p class="card-text"><h6>Токен ВБ:</h6> {{ wblk.token_wb ? wblk.token_wb : "(не заполнен)" }} </p>
      <p class="card-text"><h6>Ставка налога:</h6> {{ wblk.tax_rate }} </p>
      <router-link :to="{ name: 'WbLkEdit', params: { id: wblk.id }}" class="btn btn-primary mx-1" >Редактировать</router-link>
      <a @click="deleteWblk(wblk.id)" href="#" class="btn btn-danger mx-1">Удалить</a>
    </div>
  </div>

</template>

<script>

import {fetchDrf} from "@/common";

export default {
  name: "WbLkList",
  data(){
    return{
      wblks:[],
      auth_token: "",
      messageResult: "",
      getError: false
    }
  },

  components: {},

  created() {
    document.title = "Аккаунты ВБ"
    this.loadWblks()
  },

  methods: {
    async loadWblks(){
      let res = await fetchDrf(`/wb-lk/`)

      if (!res.gotError){
        this.wblks = res.data;
      }
      else{
        this.messageResult = res.messageResult;
        this.gotError = res.gotError;
      }
    },

    async deleteWblk(id){
      if(! confirm('Подтверждаете удаление?')) return;

      let res = await fetchDrf(`/wb-lk/${id}/`,
                               "DELETE")

      this.messageResult = res.messageResult;
      this.gotError = res.gotError;

      await this.loadWblks() // обновить список
    },
  }
}
</script>

<style scoped>

</style>