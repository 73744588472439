<template xmlns="http://www.w3.org/1999/html">
  <div class="row">
    <h1>{{getHeaderText}}</h1>
  </div>

  <div class="row">
    <div class="col-sm-auto mt-2">
      <span class="text-secondary">Токены можно взять <a href="https://seller.wildberries.ru/supplier-settings/access-to-api" target="_blank">в личном кабинете ВБ </a>.
      (выберите Контент, Статистика, Продвижение с галочкой "Только чтение")</span>
    </div>
  </div>


  <div class="row">
    <div v-if="messageResult" class="alert " :class="{ 'alert-danger': gotError, 'alert-success': !gotError }"  role="alert">
      {{ messageResult }}
    </div>
  </div>


  <div class="row">
    <div class="col-sm-auto mt-2">
      <div class="form-group">
        <label for="inputName">Название</label>
        <input v-model="name" type="text" class="form-control" id="inputName" placeholder="">
      </div>
    </div>
  </div>

  <div class="row">
    <div class="col-sm-auto mt-2">
      <div class="form-group">
        <label for="inputTokenWb">Токен ВБ</label>
        <textarea v-model="token_wb" type="text" class="form-control" id="inputTokenWb" placeholder="" rows="6"></textarea>
      </div>
    </div>
  </div>


  <div class="row">
    <div class="col-sm-auto mt-2">
      <div class="form-group">
        <label for="inputTaxRate">Ставка налога, %</label>
        <input v-model="tax_rate" type="number" class="form-control" id="inputTaxRate" placeholder="Например, 6">
      </div>
    </div>
  </div>

  <div class="row">
    <div class="col-auto">
      <button @click="updateWblk" type="button" class="btn btn-primary mt-3">Сохранить</button>
    </div>
    <div class="col-auto">
      <router-link :to="{name: 'WbLkList'}" class="btn btn-warning mt-3">Вернуться</router-link>
    </div>
  </div>

</template>

<script>
import {fetchDrf} from "@/common";

export default {
  name: "WbLkEdit",
  props: ['id'],
  data(){
    return {
      wblk: undefined,
      name: "",
      token_wb: "",
      tax_rate: undefined,
      messageResult: "",
      gotError: false
    }
  },

  created() {
    document.title = "Аккаунт ВБ"

    this.loadWblk();


  },

  computed: {
    getHeaderText() {
       if (this.id == 0) return "Привязать аккаунт ВБ";
       else return "Редактировать привязку аккаунта ВБ";
    },
  },

  methods: {

    async loadWblk() {
      // Если создаем с нуля - нечего подгружать
      if (this.id == 0) return

      let res = await fetchDrf(`/wb-lk/${this.id}/`)

      if (!res.gotError){
          this.name = res.data["name"]
          this.token_wb = res.data["token_wb"]
          this.tax_rate = res.data["tax_rate"]
      }
      else {
       this.messageResult = res.messageResult;
       this.gotError = res.gotError
      }
    },

    async updateWblk() {
      if (this.id != 0) {
        let jsonBody = {
          name: this.name,
          token_wb: this.token_wb,

          tax_rate: this.tax_rate,

        }

        let res = await fetchDrf(`/wb-lk/${this.id}/`,
            "PUT",
            jsonBody
        )

        this.messageResult = res.messageResult;
        this.gotError = res.gotError

        await this.loadWblk()
      }

      // Создаем новую запись
      else {
        let jsonBody = {
          name: this.name,
          token_wb: this.token_wb,
          tax_rate: this.tax_rate,
        }

        let res = await fetchDrf(`/wb-lk/`,
                                 "POST",
                                 jsonBody)

        if (!res.gotError){
          this.$router.push({name: 'WbLkList'})
        }
        else {
          this.messageResult = res.messageResult;
          this.gotError = res.gotError
        }
      }
    }
  }
}
</script>

<style scoped>

</style>