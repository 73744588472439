<template>

  <div class="col-md-5">

  <div class="text-end">
    <button @click="logout" type="button" class="btn btn-danger mb-3 my-2">Выйти с сайта</button>
  </div>

  <h5>Редактирование профиля*</h5>
  <div class="row">
    <div class="col-sm-auto mt-2">
      <router-link :to="{name: 'OzonLkList'}" class="btn btn-link mx-0 p-0">Редактировать токены ОЗОН</router-link>
    </div>
  </div>

  <div class="row">
    <div class="col-sm-auto mt-2">
      <router-link :to="{name: 'WbLkList'}" class="btn btn-link mx-0 p-0">Редактировать токены ВБ</router-link>
    </div>
  </div>

  <div class="row">
    <div class="col-sm-auto mt-2">
      <router-link :to="{name: 'WbWhRel'}" class="btn btn-link mx-0 p-0">Редактировать скрытые склады ВБ</router-link>
    </div>
  </div>

  <div class="row">
    <div class="col-sm-6 mt-2">
      <div class="form-group">
        <label for="inputName">Имя</label>
        <input v-model="username" disabled="true" type="text" class="form-control" id="inputName" aria-describedby="inputName">
      </div>
    </div>
  </div>

  <div class="row">
    <div class="col-sm-6 mt-2">
      <div class="form-group">
        <label for="inputTgId">ID в Телеграм (заполняется автоматически)</label>
        <input v-model="tg_id" disabled="true" type="text" class="form-control" id="inputTgId" aria-describedby="inputTgId" placeholder="">
      </div>
    </div>
  </div>

  <div class="row">
    <div class="col-sm-auto mt-2">
      <div class="form-group">
        <input id="cbShowDashboard" class="form-check-input align-middle me-1" type="checkbox" v-model="show_dashboard" @change="updateUser">
        <label for="cbShowDashboard"  class="col-form-label align-middle">Выводить "Дашбоард" вместо "Первых шагов" при входе </label>
      </div>
    </div>
  </div>

<!--  <div class="row">-->
<!--    <div class="col-sm-auto mt-2">-->
<!--      <button @click="updateUser" type="button" class="btn btn-primary">Обновить профиль</button>-->
<!--    </div>-->
<!--  </div>-->

  <div class="row">
    <div class="col-sm-auto mt-2">
      <div v-if="messageResult" class="alert " :class="{ 'alert-danger': gotError, 'alert-success': !gotError }"  role="alert">
        {{ messageResult }}
      </div>
    </div>
  </div>

  <div class="row">
    <div class="col-sm-6 mt-4">
      <label for="inputNewPassword">Новый пароль</label>
      <div class="input-group" >
        <input v-model="new_password" :type="newPasswordFieldType" class="form-control" id="inputNewPassword" aria-describedby="inputNewPassword" placeholder="Новый пароль">
        <button type="button" @click="switchVisibilityNewPassword" class="btn btn-secondary btn-sm" >Показать</button>
      </div>
    </div>
  </div>


  <div class="row">
    <div class="col-sm-6 mt-2">
      <label for="inputCurrentPassword">Текущий пароль (если устанавливаете новый пароль)</label>
      <div class="input-group" >
        <input v-model="current_password" :type="currentPasswordFieldType"  class="form-control" id="inputCurrentPassword" aria-describedby="inputCurrentPassword" placeholder="Текущий пароль">
        <button type="button" @click="switchVisibilityCurrentPassword" class="btn btn-secondary btn-sm" >Показать</button>
      </div>
   </div>
  </div>

  <div class="row">
    <div class="col-sm-auto mt-2">
      <button @click="updatePassword" type="button" class="btn btn-primary">Сменить пароль</button>
    </div>
    <div class="col-sm-auto mt-2">
      <router-link :to="{name: 'DashBoard'}" class="btn btn-warning">Отмена</router-link>
    </div>
  </div>


<!--  </form>-->
  </div>

</template>

<script>
  import {fetchDrf} from "@/common";

  export default {
    name: "UserSelfEdit",
    data() {
      return {
          username: "",
          tg_id: "",
          show_dashboard: false,

          new_password: "",
          current_password: "",

          currentPasswordFieldType: "password",
          newPasswordFieldType: "password",
          messageResult: "",
          gotError: false,
      }
    },

    created() {
      document.title = "Редактирование профиля"
      this.loadUser();
    },

    methods: {
      switchVisibilityCurrentPassword() {
        this.currentPasswordFieldType = (this.currentPasswordFieldType === "password" ? "text" : "password");
      },

      switchVisibilityNewPassword() {
        this.newPasswordFieldType = (this.newPasswordFieldType === "password" ? "text" : "password");
      },

      async logout(){
        let res = await fetchDrf(
         `/auth/token/logout/`,
         "POST",
         {},
         "",
         204,
         true
        )

        if (!res.gotError){
           localStorage.removeItem("auth_token");
           this.$router.push({ name: 'PageHome' });
        }
        else {
           this.messageResult = res.messageResult;
           this.gotError = res.gotError;
        }
    },

    async loadUser() {
      let res = await fetchDrf(
        `/auth/users/me/`,
        "GET",
        {},
        "",
        undefined,
        true
      )

      if (!res.gotError){
          this.username = res.data["username"]
          this.tg_id = res.data["tg_id"]
          this.show_dashboard = res.data["show_dashboard"]
      }
      else {
       this.messageResult = res.messageResult;
       this.gotError = res.gotError
      }
    },

    async updateUser() {
      let jsonBody = {
        show_dashboard: this.show_dashboard,
      }

      let res = await fetchDrf(
      `/auth/users/me/`,
       "PUT",
       jsonBody,
       "Успешно обновили профиль",
       200,
       true
      )

      this.messageResult = res.messageResult;
      this.gotError = res.gotError

      // Если не возникло ошибки обновления - перегрузим страницу
      if(!this.gotError) await this.loadUser()
    },


    async updatePassword() {
      let jsonBody = {new_password: this.new_password,
                      re_new_password: this.new_password,
                      current_password: this.current_password,
                     }

      let res = await fetchDrf(
      `/auth/users/set_password/`,
       "POST",
       jsonBody,
       "Успешно обновили ПАРОЛЬ",
       204,
       true
      )

      this.messageResult = res.messageResult;
      this.gotError = res.gotError;
    }
  }
}
</script>

<style scoped>

</style>