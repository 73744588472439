<template>

  <Toast :toastIsShowed="toastIsShowed" :gotError="gotError" :toastMessage="toastMessage" />

  <div ref="divModalWindow" class="modal fade" tabindex="-1" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-body">

          <div class="row">
            <div class="col-12">
              <span class="fw-bold">{{textModalHeader}}</span>
              <button type="button" class="btn-close float-end" aria-label="Close" @click="hideModalWindow"></button>
            </div>
          </div>

          <div class="row mt-2">
            <div class="col-12" >
              <span v-html="textModalContent"></span>
            </div>
          </div>

        </div>
      </div>
    </div>
  </div>


  <div class="row mt-1">
    <div class="col-auto">
      <div class="input-group">
        <button class="btn btn-light" data-bs-toggle="collapse" data-bs-target="#collapseShowHeaders" aria-expanded="false" aria-controls="collapseShowHeaders">
          <i class="bi bi-gear-fill text-secondary" data-bs-toggle="tooltip" title="Выберите какие столбцы показать и их порядок"></i>
          <span class="ms-1" data-bs-toggle="tooltip" title="Выберите какие столбцы показать и их порядок">Настройки таблицы {{activeHeadersCount}} / {{allHeadersCount}}</span>
        </button>

        <button class="btn btn-light" @click="downloadExcel" data-bs-toggle="tooltip" title="Скачать как Excel">
          <i class="bi bi-file-earmark-excel text-secondary" ></i>
        </button>

      </div>
    </div>

  </div>

  <div class="row mt-1">
    <div class="col-12">
      <div class="collapse" id="collapseShowHeaders">
        <div class="row mt-1">
          <div class="col-auto">
            <button class="btn btn-danger" @click="resetColumnsSettings">Сбросить настройки столбцов</button>
          </div>
        </div>
        <div class="row mt-2">
          <div class="col-6">
            <span class="text-secondary">Пункты можно перетаскивать чтобы поменять местами колонки</span>
            <br>
            <input :id="'cb_column_select_all'" @change="selectAllHeaders" class="form-check-input me-1" type="checkbox" v-model="allHeadersAreSelected" >
            <label :for="'cb_column_select_all'" class="form-check-label fw-bold mt-1">Выбрать все</label>

            <draggable :list="headers" @end="saveOptions">
              <template #item="{element}">
                <div class="list-group-item border-0" >
                  <input :id="'cb_column_' + element.id" class="form-check-input me-1" type="checkbox" v-model=element.active @change="saveOptions" >
                  <label :for="'cb_column_' + element.id" class="form-check-label" v-html="element.name"></label>
                </div>
              </template>
            </draggable>
          </div>

          <div class="col-6" v-if="headers2.length > 1">
            <input :id="'cb_column_select_all'" @change="selectAllHeaders2" class="form-check-input me-1" type="checkbox" v-model="allHeadersAreSelected2" >
            <label :for="'cb_column_select_all'" class="form-check-label fw-bold">Выбрать все</label>

                <template v-for="element in headers2">
<!--                  <template v-if="!element.hideSelect">-->
                    <div class="list-group-item border-0" >
                      <input :id="'cb_column2_' + element.id" class="form-check-input me-1" type="checkbox" v-model=element.active @change="saveOptions" >
                      <label :for="'cb_column2_' + element.id" class="form-check-label">{{element.name}}</label>
                    </div>
<!--                  </template>-->
                </template>

<!--                <div v-for="element in headers2" class="list-group-item border-0" >-->
<!--                  <input :id="'cb_column2_' + element.id" class="form-check-input me-1" type="checkbox" v-model=element.active @change="saveOptions" >-->
<!--                  <label :for="'cb_column2_' + element.id" class="form-check-label">{{element.name}}</label>-->
<!--                </div>-->

<!--            <draggable :list="headers2" @end="saveOptions">-->
<!--              <template #item="{element}">-->
<!--                <div class="list-group-item border-0" >-->
<!--                  <input :id="'cb_column2_' + element.id" class="form-check-input me-1" type="checkbox" v-model=element.active @change="saveOptions" >-->
<!--                  <label :for="'cb_column2_' + element.id" class="form-check-label">{{element.name}}</label>-->
<!--                </div>-->
<!--              </template>-->
<!--            </draggable>-->
          </div>

        </div>

      </div>
    </div>
  </div>

  <div class="row">
    <div class="col-auto">
      <table class="table" :class="tableClass">
        <thead :class="tableTheadClass">
        <tr>
          <th v-for="header in headers" scope="col" class="sortable align-top" :class="{ 'd-none': !header.active}" style="line-height: 1.2;" >
            <a @click="sortList(header.id, this, arrTable)"  class="link-dark text-decoration-none" href="#">
            <span v-html="header.name"></span>
            </a>
            <i v-if="header.hint" class="bi bi-info-circle ms-1" data-bs-toggle="tooltip" :title="header.hint"></i>
            <i :class="getIconSortClass(header.id, sortBy, sortedbyASC)"></i>
          </th>

          <th v-for="header in headers2" scope="col" class="sortable align-top" :class="{ 'd-none': !header.active}" style="line-height: 1.2;" >
            <a @click="sortList(header.id, this, arrTable)"  class="link-dark text-decoration-none" href="#">{{header.name}}</a>
            <i v-if="header.hint" class="bi bi-info-circle ms-1" data-bs-toggle="tooltip" :title="header.hint"></i>
            <i :class="getIconSortClass(header.id, sortBy, sortedbyASC)"></i>
          </th>
        </tr>
        </thead>


        <thead v-if="showTotalRow" >
          <tr>
            <td v-for="(header, headerIndex) in headers" class="text-nowrap text-truncate table-secondary fw-bolder align-top" :style="{'max-width': header.width}" :key="headerIndex"
              :class="getHeaderClass(header)"
            >
              <span v-if="header.totalRow">

                <template v-if="header.type=='order'">
                  {{ prepareCell(totalColumns[header.id], header.type, 0) }}
                  <br>
                  <small class="text-secondary fw-normal">
                    {{ prepareCell(getOrderSumTotal(header.type, header.id), "int", 0) }}
                  </small>
                </template>
                <template v-else>
                  {{ prepareCell(totalColumns[header.id], header.type, 0) }}
                </template>

              </span>
              <span v-else-if="header.type==TYPE_COL_OWN_PRODUCT_ID || header.type==COL_WB_OWN_RREPORT_ID || header.type==COL_ARTICLE_WB">
                Итого
              </span>
            </td>

            <td v-for="(header, headerIndex) in headers2" class="text-nowrap text-truncate table-secondary fw-bolder align-top" :style="{'max-width': header.width}" :key="headerIndex"
              :class="{
                'd-none': !header.active,
                'text-end': ((header.type=='int' || header.type=='float' || header.type=='order') && !header.class),
                [header.class]: (header.active && header.class)
              }"
            >
              <span v-if="header.totalRow">
                <template v-if="header.type=='order'">
                  {{ prepareCell(totalColumns[header.id], header.type, 0) }}
                  <br>
                  <small class="text-secondary fw-normal">
                    {{ prepareCell(getOrderSumTotal(header.type, header.id), "int", 0) }}
                  </small>
                </template>
                <template v-else>
                  {{ prepareCell(totalColumns[header.id], header.type, 0) }}
                </template>
              </span>
              <span v-else-if="header.type==TYPE_COL_OWN_PRODUCT_ID || header.type==COL_WB_OWN_RREPORT_ID || header.type==COL_ARTICLE_WB">
                Итого
              </span>
            </td>

          </tr>
        </thead>


        <tbody>
        <tr v-for="(item,rowIndex) in items" :key="rowIndex">
          <td v-for="(header, headerIndex) in headers" class="text-nowrap text-truncate" :title="item[header.title]" :style="{'max-width': header.width}" :key="headerIndex"
              :class="getCellClass(header, item[header.id])"
          >
            <slot :item="item" :header="header">

              <span v-if="header.type=='foto'">
                <img :src="item[header.id]" width="30" @error="item[header.id] = defaultFotoLink()">
              </span>

              <span v-else-if="header.type==COL_ARTICLE_WB">
                <a :href=getWbUrlByNmId(item[header.id]) target="_blank" class="link-secondary" ><i class="bi bi-paperclip" title="Перейти к товару на WB" data-bs-toggle="tooltip"></i></a>
                <router-link :to="{ name: 'WbOwnProductEdit', params: { id: item[COL_OWN_PRODUCT_ID] }}" class="link-secondary"><i class="bi bi-pencil-square" title="Перейти к товару" data-bs-toggle="tooltip"></i></router-link>
                {{ item[header.id] }}
              </span>

              <span v-else-if="header.type==COL_ARTICLE_WB_NOT_OWN">
                <a :href=getWbUrlByNmId(item[header.id]) target="_blank" class="link-secondary" ><i class="bi bi-paperclip" title="Перейти к товару на WB" data-bs-toggle="tooltip"></i></a>
                {{ item[header.id] }}
              </span>

              <span v-else-if="header.type==COL_WB_OWN_RREPORT_ID">
                <a :href=getWbRReportUrlById(item[header.id]) target="_blank" class="link-secondary" ><i class="bi bi-paperclip" title="Перейти к отчету на WB" data-bs-toggle="tooltip"></i></a>
                <router-link :to="{ name: 'WbOwnRReportEdit', params: { id: item[COL_ID] }}" class="link-secondary"><i class="bi bi-pencil-square" title="Перейти к отчету" data-bs-toggle="tooltip"></i></router-link>
                {{ item[header.id] }}
              </span>

              <span v-else-if="header.type==COL_ADV_BETS_REQUEST_ID">
                <router-link :to="{ name: 'WbAdvBetsRequestEdit', params: { id: item[COL_ID] }}" class="link-secondary"><i class="bi bi-pencil-square" title="Перейти к запросу" data-bs-toggle="tooltip"></i></router-link>
                {{ item[header.id] }}
              </span>

              <span v-else-if="header.type==TYPE_COL_ADV_CAMPAIGN_ID">
                <a :href="getWbAdvCampaignUrl(item[COL_ADV_TYPE], item[COL_WB_ID])" target="_blank" class="link-secondary" ><i class="bi bi-paperclip" title="Перейти к кампании на WB" data-bs-toggle="tooltip"></i></a>
                {{ item[header.id] }}
              </span>

              <span v-else-if="header.type==TYPE_COL_CHART_ORDERS">
                <div style="width: 220px; height: 45px">
                  <BarChartOrders :chartData="ordersChartData(item)"/>
                </div>
              </span>

              <span v-else-if="header.type==TYPE_COL_CHART_ADV">
                <div style="width: 220px; height: 45px">
                  <BarChartAdv :chartData="advChartData(item)"/>
                </div>
              </span>

              <span v-else-if="header.type==TYPE_COL_CHART_WH_STOCKS">
                <div style="width: 140px; height: 45px">
                  <PieChartWhStocks :chartData="whStocksChartData(item)"/>
                </div>
              </span>

              <span v-else-if="header.type==TYPE_COL_CHART_WH_ORDERS">
                <div style="width: 140px; height: 45px">
                  <PieChartWhOrders :chartData="whOrdersChartData(item)"/>
                </div>
              </span>

              <span v-else-if="header.type==TYPE_COL_BARCHART_WH_ORDERS">
                <div style="width: 220px; height: 45px">
                  <BarChartWhOrders :chartData="whOrdersBarChartData(item)"/>
                </div>
              </span>

              <span v-else-if="header.type==TYPE_COL_WB_ORDERS_BARCHART">
                <div style="width: 220px; height: 45px">
                  <BarChartUniversal :chartData="ordersWbBarChartData(item)" withYAxis @click-barchart="onClickBarchartWbOrders"/>
                </div>
              </span>

              <span v-else-if="header.type==TYPE_COL_OZON_ORDERS_BARCHART">
                <div style="width: 220px; height: 45px">
                  <BarChartUniversal :chartData="ordersOzonBarChartData(item)" withYAxis @click-barchart="onClickBarchartOzonOrders"/>
                </div>
              </span>

              <span v-else-if="header.type==TYPE_COL_ADV_STATS_BARCHART">
                <div style="width: 220px; height: 45px">
                  <BarChartUniversal :chartData="advStatBarChartData(item)" withYAxis @click-barchart="onClickBarchartAdvStat"/>
                </div>
              </span>

              <span v-else-if="header.type==TYPE_COL_ADV_OWN_PRODUCTS">
                <a v-for="product in item[COL_ADV_OWN_PRODUCTS]" :href=getWbUrlByNmId(product.nm_id) target="_blank" >
                  <img :src="product.foto_link" width="30">
                </a>
              </span>

              <span v-else-if="header.type==TYPE_COL_ADV_STATUS">
                <span class="badge" :class=getAdvStatusBadgeClass(item[COL_ADV_STATUS])>
                  {{ item[header.id] }}
                </span>
              </span>

              <span v-else-if="header.type==TYPE_COL_ADV_TYPE_NAME_SHORT">
                <span class="badge" :class=getAdvTypeNameShortBadgeClass(item[COL_ADV_TYPE])>
                  {{ getAdvTypeNameShortText(item[COL_ADV_TYPE]) }}
                </span>
              </span>

              <span v-else-if="header.type && header.type.includes(COL_ADVERT)">
                <div class="row gx-1">
                  <div class="col-auto">
                    <img width="30" :src="item[header.id].foto_link" @error="item[header.id].foto_link = defaultFotoLink()"/>
                  </div>
                  <div class="col-auto">
                    <span class="fw-bold mx-2"> {{ item[header.id].cpm }} </span>
                    <br/>
                    <small class="text-muted mx-2">
                      Арт: <a class="link-secondary" target="_blank"
                              :href=getWbUrlByNmId(item[header.id].nm_id)>{{ item[header.id].nm_id }}</a>
                    </small>
                  </div>
                </div>
              </span>

              <span v-else-if="header.type==this.COL_RENEW_ADV">
                <a @click="showToast(this, 'Сделали запрос, подождите несколько секунд...', false, 10000); makeAdvBetsRequestAndFollow(item[COL_ADV_KEYWORD])" class="btn btn-primary">↻</a>
              </span>

              <span v-else-if="header.type==this.TYPE_COL_DELETE_BUTTON">
                <a @click="deleteItem(header, item) " class="btn btn-light"><i class="bi bi-trash3-fill"></i></a>
              </span>

              <span v-else-if="header.type=='checkbox'">
                <input type="checkbox" v-model="item[header.id]" @change="updateCheckbox(header, item, header.id)">
              </span>

              <span v-else-if="header.type=='order'">
                {{ prepareCell(item[header.id], "int") }}
                <br/>
                <small class="text-secondary">
                  {{ prepareCell(getOrderSum(item, header.type, header.id), "int") }}
                </small>
              </span>

              <span v-else-if="header.editable_cell_path">
                <editable-cell
                    :type="header.type"
                    :value="item[header.id]"
                    :displayedValue="prepareCell(item[header.id], header.type)"
                    @input="updateEditableCell(header, item, header.id, $event)" />
              </span>

              <span v-else-if="header.modal_window_content">
                <span @click="showModalWindowClick(item, header)">
                  {{ prepareCell(item[header.id], header.type) }}
                </span>
              </span>

              <span v-else>
                {{ prepareCell(item[header.id], header.type) }}
              </span>
            </slot>
          </td>

          <td v-for="(header, headerIndex) in headers2" class="text-nowrap text-truncate" :title="item[header.title]" :style="{'max-width': header.width}" :key="headerIndex" data-bs-toggle="tooltip"
              :class="getCellClass(header, item[header.id])">
            <span v-if="header.active">
              <slot :item="item" :header="header">
                <span v-if="header.type=='order'">
                  {{ prepareCell(item[header.id], "int") }}
                  <br/>
                  <small class="text-secondary">
                    {{ prepareCell(getOrderSum(item, header.type, header.id), "int") }}
                  </small>
                </span>
                <span v-else>
                  {{ prepareCell(item[header.id], header.type) }}
                </span>
              </slot>
            </span>
          </td>
        </tr>
        </tbody>

      </table>

    </div>
  </div>

</template>

<script>
import {Modal, Tooltip} from 'bootstrap'
import draggable from "vuedraggable";

import EditableCell from "@/components/EditableCell";
import Toast from "@/components/Toast";

import BarChartOrders from "@/components/BarChartOrders";
import BarChartAdv from "@/components/BarChartAdv";
import PieChartWhStocks from "@/components/PieChartWhStocks";
import PieChartWhOrders from "@/components/PieChartWhOrders";
import BarChartWhOrders from "@/components/BarChartWhOrders";
import BarChartUniversal from "@/components/BarChartUniversal";

import {
  getIconSortClass,
  sortList,
  prepareCell,
  getWbUrlByNmId,
  getWbRReportUrlById,
  getWbAdvCampaignUrl,
  exportToExcel,
  fetchDrf,
  defaultFotoLink,
  makeAdvBetsRequestAndFollow,
  showToast,
  getAdvStatusBadgeClass,
  COL_OWN_PRODUCT_ID,
  COL_WB_ID,
  COL_ID,
  COL_WB_OWN_RREPORT_ID,
  COL_ADV_BETS_REQUEST_ID,
  COL_ARTICLE_WB,
  COL_ARTICLE_WB_NOT_OWN,
  COL_ADVERT,
  COL_ADV_KEYWORD,
  COL_RENEW_ADV,
  COL_ADV_OWN_PRODUCTS,
  COL_ADV_TYPE,
  COL_ADV_TYPE_NAME,
  COL_ADV_STATUS,

  COL_ORDER_QUANTITY,
  COL_ORDER_SUM,
  COL_ORDER_HINT,

  TYPE_COL_OWN_PRODUCT_ID,
  TYPE_COL_ORDER_DAY,
  TYPE_COL_DELETE_BUTTON,
  TYPE_COL_ADV_OWN_PRODUCTS,
  TYPE_COL_ADV_CAMPAIGN_ID,
  TYPE_COL_ADV_STATUS,
  TYPE_COL_CHART_ORDERS,
  TYPE_COL_CHART_WH_STOCKS,
  TYPE_COL_CHART_WH_ORDERS,
  TYPE_COL_BARCHART_WH_ORDERS,
  TYPE_COL_CHART_ADV,
  TYPE_COL_WB_ORDERS_BARCHART,
  TYPE_COL_OZON_ORDERS_BARCHART,
  TYPE_COL_ADV_TYPE_NAME_SHORT,
  TYPE_COL_ADV_STATS_BARCHART,

  WB_ADV_TYPE_CATALOG,
  WB_ADV_TYPE_CARD,
  WB_ADV_TYPE_SEARCH,
  WB_ADV_TYPE_REC,
  WB_ADV_TYPE_AUTO,
  WB_ADV_TYPE_SEARCH_AND_CARD,
  WB_ADV_TYPE_CHOICES,

  prepareDate, prepareNumber,
} from "@/common";


export default {
  name: "UniversalTable",

  components: {
    BarChartUniversal,
    BarChartOrders,
    BarChartWhOrders,
    BarChartAdv,
    PieChartWhStocks,
    PieChartWhOrders,
    draggable,
    EditableCell,
    Toast,
  },

  props: {
    items: Array,
    allHeaders: Array,
    allHeaders2: Array,
    localStorageHeadersName: String,
    localStorageHeadersName2: String,
    arrTable: Array,
    showTotalRow: Boolean,

    excelFileName: {
      type: String,
      default: "table"
    },

    tableClass: {
      type: String,
      default: "table-striped table-bordered border-ligth table-hover"
    },
    tableTheadClass: {
      type: String,
      default: "sticky-top opacity-100 bg-white"
    },
  },

  data() {
    return {
        sortedbyASC: true,
        sortBy: "",
        filterString: "",
        dataAlreadyLoaded: false,

        allHeadersAreSelected: true,
        allHeadersAreSelected2: true,
        headers: Array,
        headers2: Array,

        toastIsShowed: false,
        toastDelay: 5000,
        toastMessage: "",

        divModalWindow: undefined,
        textModalHeader: "",
        textModalContent: "",

        gotError: false,

        // COL_OWN_PRODUCT_ID: "",
    }
  },

  emits: [],

  watch:{
    // dataLoaded: {
    //   async handler(newValue, oldValue){
    //     if (!this.needDataLoaded) return
    //
    //     this.$forceUpdate()
    //   },
    // },

    allHeaders: {
      async handler(newValue, oldValue){
      },
      deep: true,
    },

    allHeaders2: {
      async handler(newValue, oldValue){
        if (this.allHeaders2){
          this.headers2 = this.allHeaders2
        }
      },
      deep: true,
    },

  },

  async beforeUpdate(){
    const tooltipTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="tooltip"]'))
    tooltipTriggerList.map(function (tooltipTriggerEl) {
      return new Tooltip(tooltipTriggerEl)
    })

    await this.restoreOptions();
  },

  async created(){
    // if (!this.tableTheadClass){
    //   this.tableTheadClass = "sticky-top opacity-100 bg-white"
    // }

    this.COL_OWN_PRODUCT_ID = COL_OWN_PRODUCT_ID
    this.COL_WB_ID = COL_WB_ID
    this.COL_ID = COL_ID
    this.COL_WB_OWN_RREPORT_ID = COL_WB_OWN_RREPORT_ID
    this.COL_ADV_BETS_REQUEST_ID = COL_ADV_BETS_REQUEST_ID
    this.COL_ADVERT = COL_ADVERT
    this.COL_ARTICLE_WB = COL_ARTICLE_WB
    this.COL_ARTICLE_WB_NOT_OWN = COL_ARTICLE_WB_NOT_OWN
    this.COL_ADV_KEYWORD = COL_ADV_KEYWORD
    this.COL_RENEW_ADV = COL_RENEW_ADV

    this.COL_ORDER_QUANTITY = COL_ORDER_QUANTITY
    this.COL_ORDER_SUM = COL_ORDER_SUM
    this.COL_ORDER_HINT = COL_ORDER_HINT
    this.COL_ADV_OWN_PRODUCTS = COL_ADV_OWN_PRODUCTS
    this.COL_ADV_TYPE = COL_ADV_TYPE
    this.COL_ADV_TYPE_NAME = COL_ADV_TYPE_NAME
    this.COL_ADV_STATUS = COL_ADV_STATUS

    this.TYPE_COL_OWN_PRODUCT_ID = TYPE_COL_OWN_PRODUCT_ID
    this.TYPE_COL_ORDER_DAY = TYPE_COL_ORDER_DAY
    this.TYPE_COL_DELETE_BUTTON = TYPE_COL_DELETE_BUTTON
    this.TYPE_COL_ADV_OWN_PRODUCTS = TYPE_COL_ADV_OWN_PRODUCTS
    this.TYPE_COL_ADV_CAMPAIGN_ID = TYPE_COL_ADV_CAMPAIGN_ID
    this.TYPE_COL_ADV_STATUS = TYPE_COL_ADV_STATUS
    this.TYPE_COL_CHART_ORDERS = TYPE_COL_CHART_ORDERS
    this.TYPE_COL_CHART_WH_STOCKS = TYPE_COL_CHART_WH_STOCKS
    this.TYPE_COL_CHART_WH_ORDERS = TYPE_COL_CHART_WH_ORDERS
    this.TYPE_COL_BARCHART_WH_ORDERS = TYPE_COL_BARCHART_WH_ORDERS
    this.TYPE_COL_CHART_ADV = TYPE_COL_CHART_ADV
    this.TYPE_COL_WB_ORDERS_BARCHART = TYPE_COL_WB_ORDERS_BARCHART
    this.TYPE_COL_OZON_ORDERS_BARCHART = TYPE_COL_OZON_ORDERS_BARCHART
    this.TYPE_COL_ADV_TYPE_NAME_SHORT = TYPE_COL_ADV_TYPE_NAME_SHORT
    this.TYPE_COL_ADV_STATS_BARCHART = TYPE_COL_ADV_STATS_BARCHART

    this.WB_ADV_TYPE_CATALOG = WB_ADV_TYPE_CATALOG
    this.WB_ADV_TYPE_CARD = WB_ADV_TYPE_CARD
    this.WB_ADV_TYPE_SEARCH = WB_ADV_TYPE_SEARCH
    this.WB_ADV_TYPE_REC = WB_ADV_TYPE_REC
    this.WB_ADV_TYPE_AUTO = WB_ADV_TYPE_AUTO
    this.WB_ADV_TYPE_SEARCH_AND_CARD = WB_ADV_TYPE_SEARCH_AND_CARD
    this.WB_ADV_TYPE_CHOICES = WB_ADV_TYPE_CHOICES

    await this.restoreOptions();
  },

  computed: {
    allHeadersCount(){
      let count = 0

      try{
        for (let i = 0; i < this.allHeaders.length; i++){
          count += 1
          // if (!this.allHeaders[i].hideSelect)
        }
      }
      catch{
      }

      try{
        for (let i = 0; i < this.allHeaders2.length; i++){
          count += 1
        }
      }
      catch{
      }

      return count
    },

    activeHeadersCount(){
      let count = 0;

      try{
        for (let i = 0; i < this.headers.length; i++){
          // && !this.headers[i].hideSelect
          if (this.headers[i].active) count += 1
        }
      }
      catch {
      }

      try{
        for (let i = 0; i < this.headers2.length; i++){
          if (this.headers2[i].active) count += 1
        }
      }
      catch {
      }

      return count
    },

    totalColumns(){
      let columns = {}

      for (let i = 0; i < this.headers.length; i++){
        if (!this.headers[i].totalRow) {
          columns[this.headers[i].id] = "-"
        }
        else{
          let sum = 0

          for(let j=0; j < this.items.length; j++){
            let cur_val = parseFloat(this.items[j][this.headers[i].id])
            if (!cur_val) cur_val = 0
            sum += cur_val
          }
          columns[this.headers[i].id] = sum
        }
      }

      for (let i = 0; i < this.headers2.length; i++){
        if (!this.headers2[i]) continue

        if (!this.headers2[i].totalRow) {
          columns[this.headers2[i].id] = "-"
        }
        else{
          let sum = 0

          for(let j=0; j < this.items.length; j++){
            let cur_val = parseFloat(this.items[j][this.headers2[i].id])
            if (!cur_val) cur_val = 0
            sum += cur_val
          }
          if (this.headers2[i])  columns[this.headers2[i].id] = sum
        }
      }


      return columns
    },
  },

  methods:{
    getIconSortClass,
    sortList,
    // restoreColumns,
    prepareCell,
    getWbUrlByNmId,
    getWbRReportUrlById,
    getWbAdvCampaignUrl,
    exportToExcel,
    defaultFotoLink,
    makeAdvBetsRequestAndFollow,
    showToast,
    getAdvStatusBadgeClass,

    onClickBarchartWbOrders(index, item){
      if (!index) return;

      let orders_wh = item["orders_dates"][index]["orders_wh"]

      if (!orders_wh) return

      let sOrdersWh = "" ;
      let totalSum = 0;
      let totalSumSpp = 0;
      let totalQuantity = 0;
      let date = item["orders_dates"][index]["date"]
      let drrPercent = item["orders_dates"][index]["drr_percent"]
      let advCost = item["orders_dates"][index]["adv_cost"]

      for (let key in orders_wh) {
        let quantity = orders_wh[key]["quantity"]
        let sum = orders_wh[key]["sum"]
        let sumSpp = orders_wh[key]["sum_spp"]
        let wh_name = orders_wh[key]["wh_name"]

        let wh_color = orders_wh[key]["wh_color"]
        if (!wh_color) wh_color = 'grey'

        totalSum += sum
        totalSumSpp += sumSpp
        totalQuantity += quantity

        sOrdersWh += `<span class="badge" style="background-color: ${wh_color}">&nbsp;</span> ${wh_name}: <b>${quantity}</b> <br/>`
      }

      let avgSum = 0
      let avgSumSpp = 0
      let avgSppDiscountPercent = 0

      if (totalQuantity) {
        avgSum = Math.floor(totalSum / totalQuantity)
        avgSumSpp = Math.floor(totalSumSpp / totalQuantity)
      }
      if (totalSum) avgSppDiscountPercent = Math.floor((1 - (totalSumSpp/totalSum)) * 100)

      if (!avgSumSpp) avgSppDiscountPercent = undefined

      if (!drrPercent) drrPercent = undefined
      if (!advCost) advCost = undefined

      sOrdersWh += `<hr>`
      sOrdersWh += `Всего заказов: <b>${prepareNumber(totalQuantity)}</b> <br/>`
      sOrdersWh += `Сумма: <b>${prepareNumber(totalSum)}</b> <br/>`

      sOrdersWh += `Средняя цена: <b>${prepareNumber(avgSum)}</b> <br/>`
      sOrdersWh += `Средняя цена с СПП: <b>${prepareNumber(avgSumSpp)}</b> <br/>`
      sOrdersWh += `Средняя СПП: <b>${prepareNumber(avgSppDiscountPercent)}%</b> <br/>`
      sOrdersWh += `ДРР: <b>${prepareNumber(drrPercent)}%</b> <br/>`
      sOrdersWh += `Затраты на рекламу: <b>${prepareNumber(advCost)}</b> <br/>`

      this.textModalHeader = `Заказы за ${prepareDate(date)}`
      this.textModalContent = sOrdersWh
      this.showModalWindow()
    },

    onClickBarchartOzonOrders(index, item){
      if (!index) return;

      let sOrdersWh = "" ;
      let date = item["orders_dates"][index]["date"]
      let totalQuantity = item["orders_dates"][index]["quantity"]
      let totalSum = item["orders_dates"][index]["sum"]

      let avgSum = 0

      if (totalQuantity) {
        avgSum = Math.floor(totalSum / totalQuantity)
      }

      sOrdersWh += `<hr>`
      sOrdersWh += `Всего заказов: <b>${prepareNumber(totalQuantity)}</b> <br/>`
      sOrdersWh += `Сумма: <b>${prepareNumber(totalSum)}</b> <br/>`
      sOrdersWh += `Средняя цена: <b>${prepareNumber(avgSum)}</b> <br/>`

      this.textModalHeader = `Заказы за ${prepareDate(date)}`
      this.textModalContent = sOrdersWh
      this.showModalWindow()
    },


    onClickBarchartAdvStat(index, item){
      if (index == undefined) return;

      // console.log("item")
      // console.log(item)

      let stats_dates = item["stats_dates"][index] // ["adv"]

      if (!stats_dates) return

      let sStats = ""

      let name = item["name"]
      let date = stats_dates["date"]
      let views = stats_dates["views"]
      let orders_quantity = stats_dates["orders_quantity"]
      let orders_sum = stats_dates["orders_sum"]
      let cost = stats_dates["cost"]

      let costToOrdersSum = undefined
      if (orders_sum) costToOrdersSum = cost / orders_sum * 100

      sStats += `Название: <b>${name}</b> <br/>`
      sStats += `Просмотров: <b>${prepareNumber(views)}</b> <br/>`
      sStats += `Заказов: <b>${prepareNumber(orders_quantity, "-")}</b> <br/>`
      sStats += `Сумма заказов: <b>${prepareNumber(orders_sum, "-")}</b> <br/>`
      sStats += `Потрачено на рекламу: <b>${prepareNumber(cost)}</b> <br/>`

      sStats += `<hr>`
      sStats += `Потрачено на рекламу / Сумма заказов: <b>${prepareNumber(costToOrdersSum, "-")}</b> % <br/>`

      this.textModalHeader = `Реклама за ${prepareDate(date)}`
      this.textModalContent = sStats
      this.showModalWindow()
    },


    showModalWindow(){
      this.divModalWindow = new Modal(this.$refs.divModalWindow)
      this.divModalWindow.show()
    },

    hideModalWindow(){
      this.divModalWindow.hide()
    },

    showModalWindowClick(item, header){
      this.textModalHeader = header["name"]
      this.textModalContent = item[header.modal_window_content]

      this.showModalWindow()
    },

    getAdvTypeNameShortBadgeClass(adv_type){
      if (adv_type == WB_ADV_TYPE_AUTO) return "bg-warning"
      if (adv_type == WB_ADV_TYPE_SEARCH_AND_CARD) return "bg-info"

      return ""
    },

    getAdvTypeNameShortText(adv_type){
      if (adv_type == WB_ADV_TYPE_AUTO) return "А"
      if (adv_type == WB_ADV_TYPE_SEARCH_AND_CARD) return "ПК"

      return ""
    },

    getHeaderClass(header){
      if (!header.active){
        return 'd-none'
      }
      else if ((header.type=='int' || header.type=='float' || header.type=='order') && !header.class){
        return 'text-end'
      }
      else if (header.active && header.class){
        // return header.class + " table-secondary fw-bolder"
        return "table-secondary fw-bolder"
      }
      else {
        return "table-secondary fw-bolder"
      }
    },

    getOrderSumTotal(type, id) {
      if (type=='order'){
        let sum_id = id.replace("order_quantity", "order_sum",)
        sum_id = sum_id.replace("quantity_orders", "sum_orders",)

        return this.totalColumns[sum_id]
      }

      return ""
    },

    getOrderSum(item, type, id) {
      if (type=='order'){
        let sum_id = id.replace("order_quantity", "order_sum",)
        sum_id = sum_id.replace("quantity_orders", "sum_orders",)

        return item[sum_id]
      }

      return ""
    },

    getCellClass(header, value){
      let res_class = ""

      if (!header.active){
        res_class = "d-none"
      }
      else if (header.class){
        res_class = header.class
      }
      else if ( !header.class && (header.type=='int' || header.type=='float' || header.type=='order') ) {
        res_class = 'text-end'
      }

      if (header.need_fill && value === null){
        res_class += " table-danger"
      }
      else if (header.mark_not_null && value !== null){
        res_class += " table-warning"
      }

      return res_class
    },

    async resetColumnsSettings(){
      let res = await confirm('Подтверждаете сброс настроек?')
      if(! res ) return;

      localStorage.setItem(this.localStorageHeadersName, "");
      this.headers = this.restoreColumns(this.localStorageHeadersName, this.allHeaders)
      this.headers2 = this.restoreColumns(this.localStorageHeadersName2, this.allHeaders2)
    },


    async updateColumns(){
      if (this.allHeaders2){
        this.headers2 = this.restoreColumns(this.localStorageHeadersName2, this.allHeaders2)
      }
    },


    selectAllHeaders(){
      for (let i = 0; i < this.headers.length; i++){
        this.headers[i].active = this.allHeadersAreSelected
      }

      this.saveOptions()
    },

    selectAllHeaders2(){
      for (let i = 0; i < this.headers2.length; i++){
        // if (this.headers2[i].hideSelect) {
        //   continue
        // }
        this.headers2[i].active = this.allHeadersAreSelected2
      }

      this.saveOptions()
    },

    restoreOptions(){
      this.headers = this.restoreColumns(this.localStorageHeadersName, this.allHeaders)
      // this.headers2 = this.restoreColumns(this.localStorageHeadersName2, this.allHeaders2)
    },

    saveOptions(){
      localStorage.setItem(this.localStorageHeadersName, JSON.stringify(this.headers));
      localStorage.setItem(this.localStorageHeadersName2, JSON.stringify(this.headers2));
    },

    downloadExcel(){
      let dataExport = []
      let headersExport = []

      for (let i = 0; i < this.items.length; i++){
        let new_row = {}

        for (let j = 0; j < this.headers.length; j++){
          if (!this.headers[j].active) continue
          if ( this.headers[j].type == "foto") continue

          new_row[this.headers[j].id] = this.items[i][this.headers[j].id]
        }

        for (let j = 0; j < this.headers2.length; j++){
          if (!this.headers2[j].active) continue
          if ( this.headers2[j].type == "foto") continue

          new_row[this.headers2[j].id] = this.items[i][this.headers2[j].id]
        }

        dataExport.push(new_row)
      }

      for (let j = 0; j < this.headers.length; j++){
        if (!this.headers[j].active) continue
        if ( this.headers[j].type == "foto") continue

        headersExport.push(this.headers[j].name)
      }

      for (let j = 0; j < this.headers2.length; j++){
        if (!this.headers2[j].active) continue
        if ( this.headers2[j].type == "foto") continue

        headersExport.push(this.headers2[j].name)
      }

      exportToExcel(dataExport, headersExport, this.excelFileName)
    },


    async updateEditableCell(header, item, columnName, newValue){
      let oldValue = item[columnName]
      let id = item[this.COL_ID]

      if ( typeof (newValue) == 'object' ) return
      if (oldValue == newValue){
        return
      }

      let jsonBody = {}

      jsonBody[columnName] = newValue

      let res = await fetchDrf(`/${header.editable_cell_path}/${id}/`,
        "PATCH", // "PUT"
        jsonBody
      )

      // Дадим сигнал чтобы перегрузили данные
      this.$emit('data-updated');
    },


    async deleteItem(header, item){
      if(! confirm('Подтверждаете удаление?')) return;

      let id = item[this.COL_ID]

      let res = await fetchDrf(`/${header.delete_path}/${id}/`,
                               "DELETE")

      // Дадим сигнал чтобы перегрузили данные
      this.$emit('data-updated');
    },


    async updateCheckbox(header, item, columnId){

      let id = item[this.COL_ID]

      let jsonBody = {}
      jsonBody[columnId] = item[columnId]

      let res = await fetchDrf(`/${header.checkbox_path}/${id}/`,
        "PUT",
        jsonBody
      )

      if(res.gotError){
        console.log(res.messageResult)
      }

      // Дадим сигнал чтобы перегрузили данные
      this.$emit('data-updated');
    },


    restoreColumns(localStorageName, allColumns = []) {

        let columns = new Array()
        let alreadyUsedColumns = new Array()

        // var allColumnsCopy = allColumns.slice(0);

        let allColumnsCopy = allColumns.map((x) => x); // var

        // Если данные были сохранены - восстановим их
        if (localStorage.getItem(localStorageName)) {
            let columnnsRestored = JSON.parse(localStorage.getItem(localStorageName));

            for (let i = 0; i < columnnsRestored.length; i++) {
                for (let j = 0; j < allColumnsCopy.length; j++) {

                    // Избегаем дублирования
                    if (alreadyUsedColumns.includes(allColumnsCopy[j].id)) {
                        continue
                    }

                    if (columnnsRestored[i].id == allColumnsCopy[j].id) {
                        let copyRow = { ...allColumnsCopy[j] };

                        copyRow.active = columnnsRestored[i].active
                        columns.push(copyRow)
                        alreadyUsedColumns.push(copyRow.id)
                        break
                    }
                }
            }
        }

        // Если появиласть в принципе новая колонка - сделаем ее последней
        for (let i = 0; i < allColumnsCopy.length; i++) {
            if (!alreadyUsedColumns.includes(allColumnsCopy[i].id)) {
                columns.push(allColumnsCopy[i])
            }
        }

        return columns
    },

    ordersChartData(item){
      let orders = item["wb_own_orders"]
      let labels = []
      let datasetOrdersOrganic = []
      let datasetOrdersAdv = []

      for (let key in orders){
        labels.push(prepareDate(key))

        let quantity = orders[key]["quantity"] ?? 0
        let adv_quantity = orders[key]["adv_quantity"] ?? 0

        // На всякий случай проверка и отнимаем
        if (quantity >= adv_quantity) {
          quantity -= adv_quantity
        }
        else{
          console.log(`Ошибка - рекламных заказов ${item["nm_id"]}  ${key}  ${adv_quantity} больше чем просто заказов ${quantity}!`)
          adv_quantity = quantity // Хотя бы так
        }

        datasetOrdersOrganic.push(quantity)
        datasetOrdersAdv.push(adv_quantity)
      }


      labels = labels.reverse()
      datasetOrdersOrganic = datasetOrdersOrganic.reverse()
      datasetOrdersAdv = datasetOrdersAdv.reverse()


    let data = {
        labels: labels,
        datasets: [{
          label: 'Органические заказы',
          backgroundColor: 'blue',
          data: datasetOrdersOrganic,
          barThickness: 10,
        },
        {
          label: 'Заказы за счет рекламы',
          backgroundColor: 'red',
          data: datasetOrdersAdv,
          barThickness: 10,
        },
        ]
      }

      return data
    },


    whOrdersBarChartData(item){ // обрабатываем данные одной строки
      let orders = item["wb_own_orders"]
      let labels = []
      let datasets = [] // каждый элемент = заказы по конкретному складу за все дни

      let whColors = []

      let ordersDaysWh = []

      for (let key in orders) {
        // for (let wh in orders[key]["wh"]) {
        if ( !orders[key]["wh"] ) continue

        for (let i = 0 ; i < orders[key]["wh"].length; i++) {
          let whName = orders[key]["wh"][i]["wh_name"]
          let whColor = orders[key]["wh"][i]["wh_color"]
          whColors[whName] = whColor
        }
      }

      for (let key in orders) {
        let curDate = prepareDate(key)
        labels.push(curDate)

        ordersDaysWh[curDate] = []

        if ( !orders[key]["wh"] ) continue

        for (let i = 0 ; i < orders[key]["wh"].length; i++) {
          let whName = orders[key]["wh"][i]["wh_name"]
          let whQuantity = orders[key]["wh"][i]["quantity"]
          ordersDaysWh[curDate][ whName ] = whQuantity
        }
      }

      for (let wh in whColors){
        datasets[wh] = []
      }

      for (let wh in whColors){
        if ( !whColors[wh] ) whColors[wh] = "#808080"
      }

      for (let key in ordersDaysWh) {
        for (let wh in whColors){
          let cur_quantity = 0

          if ( ordersDaysWh[key][wh] ){
            cur_quantity = ordersDaysWh[key][wh]
          }

          datasets[wh].push(cur_quantity)
        }
      }

      // В обрабном порядке
      labels = labels.reverse()
      for (let wh in whColors){
        datasets[wh] = datasets[wh].reverse()
      }

      let data = {
        labels: labels,
        datasets: [],
      }

      for (let wh in whColors){
        data["datasets"].push(
            {
              label: wh,
              backgroundColor: whColors[wh],
              data: datasets[wh],
              barThickness: 10,
              // minBarLength: 2,
            }
        )
      }

      return data
    },

    ordersWbBarChartData(item){
      // console.log("item")
      // console.log(item)

      let orders_dates = item["orders_dates"]

      let dates = []
      let quantitys = []
      let sums = []

      let allWhs = {}

      for(let i = 0; i < orders_dates.length; i++ ){
        let orders_wh = orders_dates[i]["orders_wh"]

        for (let key in orders_wh){
          let whName =  orders_wh[key]["wh_name"]
          let whColor = orders_wh[key]["wh_color"]

          if (!whColor) whColor = 'grey'

          allWhs[key] = {
            whName: whName,
            whColor: whColor
          }
        }
      }

      // Создадим и заполним нулями
      let sums_wh = {}
      let quantitys_wh = {}

      for (let key in allWhs){
        sums_wh[key] = []
        quantitys_wh[key] = []

        for(let i = 0; i < orders_dates.length; i++ ){
          sums_wh[key][i] = 0;
          quantitys_wh[key][i] = 0;
        }
      }

      for(let i = 0; i < orders_dates.length; i++ ){
        dates.push(orders_dates[i]["date"])
        quantitys.push(orders_dates[i]["quantity"])
        sums.push(orders_dates[i]["sum"])

        let orders_wh = orders_dates[i]["orders_wh"]

        for (let key in orders_wh){
          sums_wh[key][i] = orders_wh[key]["sum"]
          quantitys_wh[key][i] = orders_wh[key]["quantity"]
        }
      }

      let chartData = {
        labels: dates,
        item: item,
        datasets: [],
      }

      for (let key in allWhs){
        let whName = allWhs[key]["whName"]
        let whColor = allWhs[key]["whColor"]

        chartData["datasets"].push({
          label: whName,
          backgroundColor: whColor,
          data: quantitys_wh[key],
          barThickness: 10,
        })
      }

      return chartData
    },


    ordersOzonBarChartData(item){
      let orders_dates = item["orders_dates"]

      let dates = []
      let quantitys = []
      let sums = []

      let defaultName = "Заказы"
      let defaultColor = "grey" // #6610f2

      let allWhs = {
        whName: defaultName,
        whColor: defaultColor
      }

      let sums_wh = {}
      let quantitys_wh = {}

       for(let i = 0; i < orders_dates.length; i++ ){
          sums_wh[defaultName] = []
          quantitys_wh[defaultName] = []

          sums_wh[defaultName][i] = 0;
          quantitys_wh[defaultName][i] = 0;
        }

      for(let i = 0; i < orders_dates.length; i++ ){
        dates.push(orders_dates[i]["date"])
        quantitys.push(orders_dates[i]["quantity"])
        sums.push(orders_dates[i]["sum"])

        sums_wh[defaultName][i] = orders_dates[i]["sum"]
        quantitys_wh[defaultName][i] = orders_dates[i]["quantity"]
      }

      let chartData = {
        labels: dates,
        item: item,
        datasets: [],
      }

      chartData["datasets"].push({
        label: defaultName,
        backgroundColor: defaultColor,
        data: quantitys_wh[defaultName],
        barThickness: 10,
      })

      return chartData
    },


    advStatBarChartData(item){
      let stats_dates = item["stats_dates"]

      let dates = []
      let views = []
      let costs = []
      let orders_quantitys = []
      let orders_sums = []

      for(let i = 0; i < stats_dates.length; i++ ){
        dates.push(stats_dates[i]["date"])
        views.push(stats_dates[i]["views"])
        costs.push(stats_dates[i]["cost"])
        orders_quantitys.push(stats_dates[i]["orders_quantity"])
        orders_sums.push(stats_dates[i]["orders_sum"])
      }

      let chartData = {
        labels: dates,
        datasets: [{
          label: 'Просмотры',
          backgroundColor: 'blue',
          data: views,
          barThickness: 10,
        },
        ],
        item: item,
      }
      return chartData
    },



    advChartData(item){
      let advStat = item["wb_own_adv_stat"]
      let labels = []
      let datasetCatalog = []
      let datasetCard = []
      let datasetSearch = []
      let datasetRec = []
      let datasetAuto = []
      let datasetSearchAndCard = []

      for (let key in advStat){
        labels.push(prepareDate(advStat[key]["date"]))

        let viewsCatalog = advStat[key]["adv_types"][WB_ADV_TYPE_CATALOG] ?? 0
        let viewsCard = advStat[key]["adv_types"][WB_ADV_TYPE_CARD] ?? 0
        let viewsSearch = advStat[key]["adv_types"][WB_ADV_TYPE_SEARCH] ?? 0
        let viewsRec = advStat[key]["adv_types"][WB_ADV_TYPE_REC] ?? 0
        let viewsAuto = advStat[key]["adv_types"][WB_ADV_TYPE_AUTO] ?? 0
        let viewsSearchAndCard = advStat[key]["adv_types"][WB_ADV_TYPE_SEARCH_AND_CARD] ?? 0

        datasetCatalog.push(viewsCatalog)
        datasetCard.push(viewsCard)
        datasetSearch.push(viewsSearch)
        datasetRec.push(viewsRec)
        datasetAuto.push(viewsAuto)
        datasetSearchAndCard.push(viewsSearchAndCard)
      }

      let data = {
        labels: labels,
        datasets: [
        {
          label: 'Каталог',
          backgroundColor: 'red',
          data: datasetCatalog,
          barThickness: 10,
        },
        {
          label: 'Карточка',
          backgroundColor: 'green',
          data: datasetCard,
          barThickness: 10,
        },
        {
          label: 'Поиск',
          backgroundColor: 'blue',
          data: datasetSearch,
          barThickness: 10,
        },
        {
          label: 'На главной',
          backgroundColor: 'yellow',
          data: datasetRec,
          barThickness: 10,
        },
        {
          label: 'Автоматическая',
          backgroundColor: 'orange',
          data: datasetAuto,
          barThickness: 10,
        },
        {
          label: 'Поиск + каталог',
          backgroundColor: 'black',
          data: datasetSearchAndCard,
          barThickness: 10,
        },
        ]
      }

      data.datasets = data.datasets.reverse()

      return data
    },

    whOrdersChartData(item){
      let whOrders = []
      let whColors = []

      let arrOrders = item["wb_own_orders"]
      for (let key in arrOrders) {

        let wh = arrOrders[key]["wh"]

        for(let i = 0; i < wh.length; i++){
          let whName = wh[i]["wh_name"]
          let whColor = wh[i]["wh_color"] //
          let whQuantity = wh[i]["quantity"]

          whColors[whName] = whColor

          if (whOrders[whName]){
            whOrders[whName] += whQuantity
          }
          else {
            whOrders[whName] = whQuantity
          }
        }
      }

      let wh_names = []
      let wh_colors = []

      for (let key in whOrders) {
        wh_names.push(key)
      }

      let wh_quantities = []

      for (let key in whOrders) {
        wh_quantities.push(whOrders[key])

        if (whColors[key] ){
          wh_colors.push(whColors[key])
        }
        else{
          wh_colors.push("#808080")
        }
      }

      let res = {
        labels: wh_names,
        datasets: [
        {
          data: wh_quantities,
          backgroundColor: wh_colors,
          hoverOffset: 1, // 4
        }]
      }

      return res
    },

    whStocksChartData(item){
      let whStocks = []
      let whColors = []

      let arrStocks = item["wb_own_stocks"]
      for (let key in arrStocks) {
        let whName = arrStocks[key]["wh_name"]
        let whColor = arrStocks[key]["wh_color"]
        let whQuantity = arrStocks[key]["quantity"]

        whColors[whName] = whColor
        whStocks[whName] = whQuantity
      }

      let wh_names = []
      let wh_colors = []

      for (let key in whStocks) {
        wh_names.push(key)
      }

      let wh_quantities = []

      for (let key in whStocks) {
        wh_quantities.push(whStocks[key])

        if (whColors[key] ){
          wh_colors.push(whColors[key])
        }
        else{
          wh_colors.push("#808080")
        }
      }

      let res = {
        labels: wh_names,
        datasets: [
        {
          data: wh_quantities,
          backgroundColor: wh_colors,
          hoverOffset: 1, // 4
        }]
      }

      return res
    },

  }
}
</script>

<style scoped>

</style>