<template>
  <h5>Аккаунты ОЗОН</h5>

  <div class="row">
    <div class="col-auto">
      <router-link :to="{name: 'UserSelfEdit'}" class="btn btn-info my-1">← вернуться</router-link>
    </div>
  </div>

  <div v-if="messageResult" class="alert " :class="{ 'alert-danger': getError, 'alert-success': !getError }"  role="alert">
    {{ messageResult }}
  </div>

  <div class="row">
    <div class="col-auto">
      <router-link :to="{ name: 'OzonLkEdit', params: { id: 0 }}" class="btn btn-info mt-3">+ Добавить аккаунт ОЗОН</router-link>
    </div>
  </div>

  <div v-for="ozonlk in ozonlks" :key="ozonlk.id" class="card  mt-3">
    <div class="card-header">
        {{ ozonlk.name }}
    </div>
    <div class="card-body" >
      <p class="card-text"><h6>Client ID:</h6> {{ ozonlk.client_id_ozon ? ozonlk.client_id_ozon : "(не заполнен)" }} </p>
      <p class="card-text"><h6>Токен ОЗОН:</h6> {{ ozonlk.token_ozon ? ozonlk.token_ozon : "(не заполнен)" }} </p>
      <router-link :to="{ name: 'OzonLkEdit', params: { id: ozonlk.id }}" class="btn btn-primary mx-1" >Редактировать</router-link>
      <a @click="deleteOzonLk(ozonlk.id)" href="#" class="btn btn-danger mx-1">Удалить</a>
    </div>
  </div>

</template>

<script>

import {fetchDrf} from "@/common";

export default {
  name: "OzonLkList",
  data(){
    return{
      ozonlks:[],
      auth_token: "",
      messageResult: "",
      getError: false
    }
  },

  components: {},

  created() {
    document.title = "Аккаунты ОЗОН"
    this.loadOzonLks()
  },

  methods: {
    async loadOzonLks(){
      let res = await fetchDrf(`/ozon-lk/`)

      if (!res.gotError){
        this.ozonlks = res.data;
      }
      else{
        this.messageResult = res.messageResult;
        this.gotError = res.gotError;
      }
    },

    async deleteOzonLk(id){
      if(! confirm('Подтверждаете удаление?')) return;

      let res = await fetchDrf(`/ozon-lk/${id}/`,
                               "DELETE")

      this.messageResult = res.messageResult;
      this.gotError = res.gotError;

      await this.loadOzonLks() // обновить список
    },
  }
}
</script>

<style scoped>

</style>